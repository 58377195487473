/*
 * @Description: 表格初始化的一系列操作包含初始化宽度等
 * @Author: James324
 * @Date: 2023-09-06 15:05:45
 * @LastEditors: James324
 * @LastEditTime: 2024-02-24 13:58:58
 */
import { toPx } from '@/utils';
import type { BaseTableProps, TableColumnConfig } from '../type';
import type { TableColumnCtx } from 'element-plus';

export function useInit(props: BaseTableProps, tableItems: any[]) {
    const editRef = ref(false);
    const customAttr = ref({ ...props.tableAttr, ...props.tableConfig.tableProps });

    /**
     * 初始化 eltable组件的高度
     */
    const tableHeight = props.modalMode ? ref(toPx(props.maxHeight?.() as number)) : ref('auto');

    /**
     * 转换 width 属性
     */
    const transformProps = (columnProps: Partial<TableColumnCtx<any>>) => {
        if ('width' in columnProps) {
            const obj = { ...columnProps, minWidth: columnProps.width };
            Reflect.deleteProperty(obj, 'width');
            return obj;
        }
        return columnProps;
    };

    /**
     * @: 是否使用阴影表格
     * @param {*} computed
     * @return {*}
     */
    const shadowTable = computed(() => {
        const fixed = !tableItems.some(item => item.columnProps?.fixed === 'right');
        return fixed && props.tableConfig.unAction;
    });

    /**
     * 判断表格头部是否需要间距
     */
    const slots = useSlots();
    const mb = computed(() => {
        const { tableHeadStart, tableHeadEnd } = slots;
        const { addTableRow, showSetting } = props;
        if (tableHeadStart || tableHeadEnd || addTableRow || showSetting) return 16;
        return 0;
    });

    /**
     * 是否显示必填图标
     */
    const showRequiredIcon = (prop: TableColumnConfig) => {
        if (prop.columnProps?.customRequired) {
            return prop.columnProps?.customRequired; // 自定义必填项
        } else if (props.addTableRow || editRef.value) {
            return prop.validProps?.required; // 表格为新增状态时获表单处于行内编辑状态时，表格是否存在表单必填项
        }
    };

    /**
     * 是否显示问题提示
     */
    const showQuestionIcon = (prop: TableColumnConfig) => prop.otherConfig?.tip;

    return {
        mb,
        customAttr,
        editRef,
        tableHeight,
        transformProps,
        showRequiredIcon,
        showQuestionIcon,
        shadowTable
    };
}
