/*
 * @Description: 表格表头定义 hook
 * @Author: James324
 * @Date: 2023-09-18 19:08:48
 * @LastEditors: James324
 * @LastEditTime: 2024-01-29 15:54:35
 */
import { cloneDeep } from 'lodash-es';
import type { BaseTableProps, TableColumnConfig } from '../type';

export default function (props: BaseTableProps, tableItems: any, tableRef?: Ref<any>) {
    const visible = ref(false);
    const excludeLabelList = ref<Array<string>>([]); // 排除的 label 表头

    /**
     * 默认在表格最前添加 【序号】列，在表格最后填添加 【操作】 列
     */
    const configItems = computed(() => {
        const defaultFrontList = props.tableConfig.unIndex
            ? []
            : [{ modelKey: 'qs_serialNo', columnProps: { label: '序号' } }];
        const defaultAfterList = props.tableConfig.unAction
            ? []
            : [{ modelKey: 'qs_operation', columnProps: { label: '操作' } }];

        return [...defaultFrontList, ...props.tableConfig.items, ...defaultAfterList];
    });

    /**
     * 设置排除 label 表头元素
     */
    const setExcludeLabelItem = (checkList: string[]) => {
        excludeLabelList.value = checkList;

        doLayout();
    };

    /**
     * 改变表格选中列
     */
    const handleChange = (checkList: string[]) => {
        setExcludeLabelItem(checkList);

        // 更新表格各列数据
        tableItems.value.map((item: any) => {
            item.hide = !filterLabel(item.columnProps!.label!);
        });

        // 更新固定列
        handleAffix(affixColumnNum.value, affixChecked.value);
    };

    /**
     * 过滤 label
     */
    const filterLabel = (label: string) => {
        return !excludeLabelList.value.includes(label);
    };

    /**
     * 拖拽位置改变
     */
    interface Position {
        newIndex: number;
        oldIndex: number;
    }
    const handleMoveChange = (position: Position) => {
        const noIndexCloumn = props.tableConfig.unIndex; // 不存在 序号 列
        const newNum = noIndexCloumn ? position.newIndex : position.newIndex - 1;
        const oldNum = noIndexCloumn ? position.oldIndex : position.oldIndex - 1;
        const oldData = tableItems.value[oldNum];

        tableItems.value.splice(oldNum, 1);
        tableItems.value.splice(newNum, 0, oldData);

        // 更新固定列
        handleAffix(affixColumnNum.value, affixChecked.value);
    };

    /**
     * 固定列修改
     */
    const affixIndexColumn = ref(false); // 是否固定序号列
    const affixChecked = ref(false); // 是否固定列
    const affixColumnNum = ref(1); // 固定列
    const handleAffix = (affixColumn: number, val: boolean) => {
        affixChecked.value = val;
        affixColumnNum.value = affixColumn;
        if (!props.tableConfig.unIndex && !excludeLabelList.value.includes('序号')) {
            // 启用了序号列
            affixIndexColumn.value = val;
            affixColumn--;
        }

        if (val && affixColumn > 0) {
            tableItems.value
                .filter((item: any) => !item.hide)
                .forEach((item: any, index: number) => {
                    if (index < affixColumn) {
                        item.fixed = true;
                    } else {
                        item.fixed = false;
                    }
                });
        } else {
            tableItems.value.forEach((item: any) => (item.fixed = false));
        }

        doLayout();
    };

    /**
     * 固定某列
     */
    const handleAffixItem = (affixItem: TableColumnConfig) => {
        tableItems.value.map((item: TableColumnConfig) => {
            if (item.columnProps?.label === affixItem.columnProps?.label) {
                item.fixed = affixItem.fixed;
            } else if (affixItem.columnProps?.label === '序号') {
                affixIndexColumn.value = !!affixItem.fixed;
            }
        });

        doLayout();
    };
    /**
     * 恢复列默认
     */
    const handleReset = () => {
        excludeLabelList.value = [];
        tableItems.value = cloneDeep(props.tableConfig.items);
        doLayout();
    };

    const doLayout = () => {
        nextTick(() => {
            tableRef?.value.doLayout();
        });
    };

    return {
        stop,
        filterLabel,
        configItems,
        visible,
        affixIndexColumn,
        setExcludeLabelItem,
        handleReset,
        handleChange,
        handleMoveChange,
        handleAffix,
        handleAffixItem
    };
}
