<script lang="ts">
import { createVNode } from 'vue';
import QIcon from '@/components/q-icon/index.vue';

export default {
    props: {
        color: {
            type: String,
            default: '#fff'
        },
        size: {
            type: String,
            default: '18px'
        }
    },

    setup(props) {
        return () =>
            createVNode(QIcon, {
                iconClass: 'loading',
                size: props.size,
                color: props.color,
                class: 'u-btn__loading'
            });
    }
};
</script>
