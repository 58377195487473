<template>
    <div class="m-page">
        <slot />
    </div>
</template>

<style scoped lang="scss">
@include b(m-page) {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 16px 16px 20px;
    background-color: var(--q-bg-color);
    border-radius: 8px;

    &.fullscreen {
        position: fixed;
        inset: 0;
        z-index: 2000;
        overflow: auto;

        @include scrollbar_y();
        @include scrollbar_hover();
    }
}
</style>
