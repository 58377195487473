/*
 * @Description: 取消重复请求
 * @Author: James324
 * @Date: 2023-08-22 14:32:59
 * @LastEditors: James324
 * @LastEditTime: 2024-01-13 16:13:16
 */
import type { AxiosRequestConfig } from 'axios';
import { PendingType } from './type';

// 移除重复请求
export const pending: Array<PendingType> = [];
export const removePending = (config: AxiosRequestConfig) => {
    for (const key in pending) {
        const item = +key;
        const cacelReqItem: PendingType = pending[key];

        // 当前请求在数组中存在时执行函数体
        if (
            cacelReqItem.url === config.url &&
            cacelReqItem.method === config.method &&
            JSON.stringify(cacelReqItem.params) === JSON.stringify(config.params) &&
            JSON.stringify(cacelReqItem.data) === JSON.stringify(config.data)
        ) {
            // 执行取消操作
            cacelReqItem.cancel('操作太频繁，请稍后再试');

            // 从数组中移除记录
            pending.splice(item, 1);
        }
    }
};

/**
 * 取消所有请求
 * @param {string} url 取消接口 URL
 */
export const removeAllPending = () => {
    for (const key in pending) {
        const item = +key;
        const cacelReqItem: PendingType = pending[key];
        // 执行取消操作
        cacelReqItem.cancel();
        // 从数组中移除记录
        pending.splice(item, 1);
    }
};

const CancelToken = axios.CancelToken;
export const setCancelConfig = (config: AxiosRequestConfig) => {
    config.cancelToken = new CancelToken(c => {
        pending.push({
            url: config.url,
            method: config.method,
            params: config.params,
            data: config.data,
            cancel: c
        });
    });
};
