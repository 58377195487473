<script setup lang="ts">
const props = defineProps(['modelValue', 'el', 'mask', 'theme']);
const emit = defineEmits(['click', 'update:modelValue']);

enum Color {
    light = 'light',
    default = 'default',
    dark = 'dark'
}

const isLocked = useScrollLock(props.el || document.body);
watch(
    () => props.modelValue,
    (value: boolean) => {
        isLocked.value = value;
    }
);

const handleClick = () => {
    if (props.mask) {
        emit('update:modelValue', false);
    }
    emit('click');
};
</script>

<template>
    <Teleport to="body">
        <div
            v-if="modelValue"
            :class="[
                'm-mask',
                {
                    'is-default': !theme || theme === Color.default,
                    'is-light': theme === Color.light,
                    'is-dark': theme === Color.dark
                }
            ]"
            @click="handleClick"
        >
            <slot />
        </div>
    </Teleport>
</template>

<style scoped lang="scss">
.m-mask {
    position: fixed;
    inset: 0;
    z-index: 3333;
}

.is-dark {
    background: rgb(0 0 0 / 60%);
}

.is-light {
    background: rgb(0 0 0 / 20%);
}

.is-default {
    background: rgb(0 0 0 / 40%);
}
</style>
