const BASE_URL = import.meta.env.VITE_APP_BASE_API; // base api
const TIME_OUT = 30000; // 超时时间

/* 后端 api 接口返回 code */
const SUCCESS_STATUS_CODES = [200, 204, 206]; // 服务器数据返回成功 status 状态码

const PASS_FAIL_URLS = ['/import']; // 错误放行地址，用于不提示错误信息

// 全局的请求次数,请求的间隙
const ERR_RETRY_AND_DELAY = [3, 1000];
const CODE_LIST = [401, 403, 404];

export { BASE_URL, TIME_OUT, SUCCESS_STATUS_CODES, PASS_FAIL_URLS, CODE_LIST, ERR_RETRY_AND_DELAY };
