import { RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router';

export const scrollBehavior = (
    _: RouteLocationNormalized,
    __: RouteLocationNormalizedLoaded,
    savePostion: any
) => {
    // return 期望滚动到哪个的位置
    if (savePostion) {
        return savePostion;
    } else {
        return { left: 0, top: 0 };
    }
};
