import { useDebounceFn } from '@vueuse/core';
import { LoadingInstance } from 'element-plus/lib/components/loading/src/loading';
import { LocalStorage } from '@/utils';
import { DARK_STATE } from '@/config';
/**
 * 全局 loading 加载
 * @param {string} target
 * @return {*}
 */
class Loading {
    private _loading: LoadingInstance | null = null;
    private _needLoadingRequestCount: number = 0;
    /**
     * 显示 loading
     */
    showLoading(target: string) {
        // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
        // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
        if (this._needLoadingRequestCount === 0 && !this._loading) {
            const getDarkState = LocalStorage.get(DARK_STATE);
            this._loading = ElLoading.service({
                lock: true,
                text: '别着急，数据正在赶来...',
                background:
                    getDarkState && getDarkState.isDark
                        ? 'rgba(20, 20, 20, 0.8)'
                        : 'rgba(255, 255, 255, 0.8)',
                customClass: 'm-table__loading',
                target: target || '#q-table'
            });
        }
        this._needLoadingRequestCount++;
    }

    /**
     * 隐藏 loading
     */
    hideLoading() {
        this._needLoadingRequestCount--;
        this._needLoadingRequestCount = Math.max(this._needLoadingRequestCount, 0); //做个保护
        if (this._needLoadingRequestCount === 0) {
            this._toHideLoading(); //关闭loading
        }
    }

    /**
     * loading 防抖
     */
    private _toHideLoading = useDebounceFn(() => {
        if (this._loading) {
            this._loading.close();
            this._loading = null;
        }
    }, 300);
}

export default new Loading();
