<script setup lang="ts">
import { tableConfig } from './config/tableConfig';
import { doGetRecord } from '@/api/common/index';
import { generateNameToLevel } from '@/config/constant';
import type { EServiceType } from '@/api/common/type';

defineProps<{
    serviceType: keyof typeof EServiceType;
    onlyExport?: boolean;
    closeDropdownClass?: boolean;
}>();

const selectTime = ref();
const form = ref({
    fileName: '',
    creator: '',
    createStartTime: '',
    createEndTime: ''
});

const title = computed(() => (type.value === 'EXPORT' ? '导出' : '导入'));
const type = ref<'IMPORT' | 'EXPORT'>('IMPORT');
const dialogVisible = ref(false);
const tableRef = ref<TableRef>();
const handleCommand = (e: 'IMPORT' | 'EXPORT') => {
    type.value = e;
    dialogVisible.value = true;
};

const handleGetData = () => {
    form.value.createStartTime = selectTime.value?.[0] || '';
    form.value.createEndTime = selectTime.value?.[1] || '';
    tableRef.value?.getData?.(form.value);
};

const handleRefresh = () => {
    form.value = {
        fileName: '',
        creator: '',
        createStartTime: '',
        createEndTime: ''
    };
    selectTime.value = [];
    tableRef.value?.getData?.({ ...form.value, size: 20, current: 1 });
};

const { handleFullscreen, getMaxHeight } = useModal();
</script>

<template>
    <div>
        <el-dropdown
            :popper-class="!closeDropdownClass ? 'dropdown-level--demote' : ''"
            trigger="click"
            style="margin-left: 16px"
            @command="handleCommand"
        >
            <el-button>
                操作记录<el-icon style="margin-left: 6px"><i-ep-arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item command="EXPORT">导出记录</el-dropdown-item>
                    <el-dropdown-item v-if="!onlyExport" command="IMPORT"
                        >导入记录</el-dropdown-item
                    >
                </el-dropdown-menu>
            </template>
        </el-dropdown>

        <q-modal
            v-model:visible="dialogVisible"
            :fullscreen="true"
            :title="title + '记录'"
            width="865px"
            :footer="false"
            :centered="true"
            @fullscreen="handleFullscreen"
        >
            <el-row justify="space-between">
                <el-col :span="11">
                    <div class="label">文件名称：</div>
                    <el-input v-model.trim="form.fileName" placeholder="请输入文件名称" />
                </el-col>
                <el-col :span="11">
                    <div class="label">操作人：</div>
                    <el-input v-model.trim="form.creator" placeholder="请输入操作人" />
                </el-col>
            </el-row>
            <el-row class="m-t-16 m-b-24" justify="space-between">
                <el-col :span="11">
                    <div class="label">创建日期：</div>
                    <el-date-picker
                        v-model="selectTime"
                        value-format="YYYY-MM-DD"
                        type="daterange"
                        range-separator="-"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                    />
                </el-col>
                <el-col :span="11" style="display: flex; align-items: center">
                    <div class="label"></div>

                    <el-button type="primary" @click="handleGetData">查询</el-button>
                    <el-button @click="handleRefresh">重置</el-button>
                </el-col>
            </el-row>

            <base-table
                :id="generateNameToLevel('1')"
                ref="tableRef"
                :max-height="getMaxHeight"
                :tableConfig="tableConfig"
                :getDataFn="doGetRecord"
                :modal-mode="true"
                :show-setting="false"
                :addedParams="{ serviceType, operateType: type }"
            >
                <template #status="{ row }"
                    >{{ title }}{{ row.status ? '成功' : '失败' }}
                </template>
            </base-table>
        </q-modal>
    </div>
</template>

<style scoped lang="scss">
@include b(el-col) {
    display: flex;
    align-items: center;
}

@include b(label) {
    width: 75px;
    font-size: 14px;
    text-align: left;
}

@include b(el-row) {
    :deep(.el-range-editor.el-input__wrapper) {
        flex: 1;
    }
}

@include b(el-input) {
    // width: 280px;
    flex: 1;
}
</style>
