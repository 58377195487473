/* 全局配置 */

class GlobalConfig {
    readonly title = '启山智软APS';
    readonly scrollSpacing = 100; // 默认滚轮距离
    readonly lapDialogTop = 50; // 重叠 dialog top 值
}

export const Global = new GlobalConfig();
export const WhiteList = ['/login']; // 路由白名单
export const TagWhiteList = ['/messages']; // tag 路由标签白名单

export const widthLevel1 = 176; // YYYY-MM-DD hh:mm:ss 备注等 最短长度
export const widthLevel2 = 140; // 编码 名称 订单号 等 最短长度
export const widthLevel3 = 110; // 日期 最短长度

export const thWordLength7 = 124; // 表头7个字的宽度
export const thWordLength6 = 116; // 表头6个字的宽度
export const thWordLength5 = 100; // 表头5个字的宽度
export const thWordLength4 = 84; // 表头4个字的宽度
export const thWordMini = 60; // 表头迷你宽
