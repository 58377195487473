import type { BaseTableConfig } from '@/components/base-ui/base-table/type';

export const tableConfig: BaseTableConfig = {
    items: [
        {
            modelKey: 'fileName',
            columnProps: {
                label: '文件名称',
                width: 260
            }
        },
        {
            slotName: 'status',
            modelKey: 'status',
            columnProps: {
                label: '状态',
                width: 140
            }
        },
        {
            modelKey: 'creator',
            columnProps: {
                label: '操作人',
                width: 140
            }
        },
        {
            modelKey: 'createTime',
            columnProps: {
                label: '操作时间',
                width: 180
            }
        }
    ],
    unAction: true
};
