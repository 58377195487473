<script setup lang="ts">
withDefaults(
    defineProps<{
        text: string;
        type?: 'success' | 'error';
    }>(),
    {
        type: 'success'
    }
);

const typeMap = {
    success: {
        icon: 'a-success1',
        prefix: '导入成功：'
    },

    error: {
        icon: 'a-warning1',
        prefix: '导入失败：'
    }
};
</script>

<template>
    <div class="tip f14" :class="type">
        <q-icon :icon-class="typeMap[type].icon" style="margin-right: 8px" size="16px"></q-icon>

        <div class="tip__text">{{ typeMap[type].prefix }} {{ text }}</div>
    </div>
</template>

<style scoped lang="scss">
@include b(tip) {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 772px;
    padding: 10px 16px;
    margin-bottom: 16px;
    color: #000;
    border-radius: 4px;

    @include e(text) {
        max-width: 712px;
        min-height: 26px;
        max-height: 78px;
        padding-right: 5px;
        margin-right: -5px;
        overflow-y: scroll;
        line-height: 26px;

        @include scrollbar_y;
        @include scrollbar_hover;
    }
}

@include b(success) {
    background: rgb(89 205 49 / 20%);
    border: 1px solid #59cd31;
}

@include b(error) {
    background: rgb(255 53 53 / 20%);
    border: 1px solid #ff3535;
}
</style>
