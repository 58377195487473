import { cloneDeep } from 'lodash-es';
import { WhiteList } from '@/config';
import { getTitle, getDom, isArray, filterByKey } from '@/utils';
import { userAsyncRoutes, constantRoute } from '../routes';
import router from '../index';
import { Route, RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router';
import NoAuth from '@/views/NoAuth/index.vue';

/**
 * 过滤当前用户需要展示的异步路由
 * @param {any} asnycRoute 异步路由列表
 * @param {any} roles 权限列表值
 * @return {*}
 */
export const filterAsyncRoute = (asnycRoute: any, roles: string[] = []) => {
    return asnycRoute.filter((item: any) => {
        if (roles.includes(item.meta.role)) {
            if (item.children && item.children.length > 0) {
                item.children = filterAsyncRoute(item.children, roles); // 递归处理
            }
            return true;
        }
    });
};

/**
 * 动态异步路由处理
 */
export const handleAsyncRoute = (asyncMenus: string[], buttons: string[]) => {
    const paths = new Set(asyncMenus); // 路由地址去重
    const copyAsyncRoutes = cloneDeep(userAsyncRoutes);
    const asyncRoutes = deepSetRoute(copyAsyncRoutes, [...paths], buttons);
    const directoryMenuRoutes = setDirectoryMenus(asyncRoutes);

    return directoryMenuRoutes;
};

/**
 * 处理一级目录菜单中的直接二级菜单子级中若都为无权限隐藏状态，则也隐藏当前目录菜单
 */
export const setDirectoryMenus = (routes: Route[]) => {
    return routes.map(route => {
        route.meta!.hidden = route.children?.every(child => child.meta?.hidden);
        return route;
    });
};

/**
 * 在异步路由列表中判断是否存在该路由地址
 * @param {Route} route
 * @param {string} menus
 * @return {*}
 */
const checkMenu = (asyncRoute: Route, asynMenus: string[]) => {
    return asynMenus.some(menu => menu.includes(asyncRoute.path!));
};
export const hasPath = (asyncRoute: Route, asynMenus: string[], roleButtons: string[]): boolean => {
    if (asyncRoute.path === '') {
        const parentRoute = filterByKey(userAsyncRoutes, asyncRoute.parent || '', 'name') || {}; // 递归查找获取 parent 的 path
        return parentRoute.path === ''
            ? hasPath(parentRoute, asynMenus, roleButtons)
            : checkMenu(parentRoute, asynMenus);
    } else if (checkMenu(asyncRoute, asynMenus)) {
        return true;
    } else if (asyncRoute.meta?.auth) {
        try {
            // 按钮权限菜单
            const auths = asyncRoute.meta?.auth;
            return isArray(auths)
                ? (auths as string[]).some(auth => roleButtons.includes(auth)) // 路由非可见二级菜单存在多个权限标识时
                : roleButtons.includes(asyncRoute.meta.auth as string); // 路由非可见二级菜单只存在一个权限标识时
        } catch (e) {
            console.log(e);
        }
    }

    return false;
};

/**
 * 递归查找当前的 parent 的路由项目
 */

/**
 * 数组对比如果存在 children 为空数组则将路由二级菜单 children 值设置为空
 */
export const deepSetRoute = (asyncRoutes: Route[], asyncMenus: string[], roleButtons: string[]) => {
    asyncRoutes.forEach(asyncRoute => {
        if (asyncRoute.level && +asyncRoute.level > 1) {
            const hasRoutePath = hasPath(asyncRoute, asyncMenus, roleButtons); // 动态判断是否存在对应路由路径

            if (!hasRoutePath) {
                if (asyncRoute.meta) asyncRoute.meta.hidden = true;
                if (asyncRoute.component) handleNoAuthRoute(asyncRoute);
            }
        }

        if (asyncRoute.children && Array.isArray(asyncRoute.children)) {
            deepSetRoute(asyncRoute.children, asyncMenus, roleButtons);
        }
    });

    return asyncRoutes;
};

/**
 * 处理未授权菜单
 */
const handleNoAuthRoute = (route: Route) => {
    if (route.parent) {
        route.component = NoAuth;
    }
};

/**
 * @description: 初始化暗黑模式
 * @param {string} path 目标路径
 * @return {*}
 */
export const initSetDark = (path: string) => {
    const html = getDom('html')!;
    const DarkStore = themeStore();
    let isSetDark = false;

    if (path === '/login') {
        html.classList.remove('dark');
        html.classList.add('light');
        isSetDark = false;
    } else {
        if (!isSetDark && DarkStore.isDark) {
            nextTick(() => {
                html.classList.remove('light');
                html.classList.add('dark');
                isSetDark = true;
            });
        }
    }
};

/**
 * @description: 路由监听
 * @return {*}
 */
export const watchRoute = () => {
    const TagsViewStore = tagsViewStore();
    const router = useRouter();
    /**
     * 判断标签列表中是否存在该标签
     */
    const isTags = (path: string) =>
        TagsViewStore.tagsViewList.some((tag: Route) => tag.fullPath === path);

    /**
     * 当前路由是否存在放行白名单中
     */
    const isPass = (path: string) => WhiteList.includes(path);

    /**
     * 当前路由是否为虚拟路由，解决动态路由 next 两次路由记录问题
     */
    const isVirtual = (route: Route) => route.path === '/' && !route.name;

    router.afterEach(to => {
        if (!isPass(to.path)) TagsViewStore.setActiveRoute(to as any); // 避免 login, 404页面执行添加激活路由
        if (isPass(to.path) || isTags(to.fullPath) || isVirtual(to as any)) return;
        const { fullPath, meta, name, params, path, query } = to;
        TagsViewStore.addTagsViewList({
            fullPath,
            meta,
            name,
            params,
            path,
            query,
            title: getTitle(to)
        } as any);
    });
};

/**
 * 删除动态路由权限
 */
const getChildren = (routes: any[]) => {
    return routes.reduce((acc, route) => {
        acc.push(route.name);
        if (route.children) {
            acc.push(...getChildren(route.children));
        }
        return acc;
    }, []);
};

export const resetRouter = () => {
    const WhiteList = getChildren(constantRoute);
    const allRoutes = router.getRoutes();

    allRoutes.map((route: Route) => {
        if (!WhiteList.includes(route.name as string)) {
            router.removeRoute(route.name!);
        }
    });
};

/**
 * @description: 设置路由动画
 */
export const setTransitionName = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded
) => {
    const toDepth = to.path.split('/').length;
    const fromDepth = from.path.split('/').length;
    to.meta.transition = toDepth <= fromDepth ? 'slide-right' : 'slide-left';
};
