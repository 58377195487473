export interface MessageType {
    type?: 'success' | 'info' | 'warning' | 'error' | 'pending';
    text: string;
    unAutoClose?: boolean;
    top?: number;
    closeTime?: string | number;
    id: string;
    left?: string;
}

// message函数对象下面的函数类型
export type MessageFunc = Record<
    'success' | 'info' | 'warning' | 'error' | 'pending',
    (option: Omit<MessageType, 'id'> | string) => () => void
>;

// message函数类型
export type Message = (content: Omit<MessageType, 'id'>) => () => void;

//  message结合类型
export type TMessage = Message & MessageFunc;

// messageType类型
export enum MessageTypeMap {
    success = 'success',
    warning = 'warning',
    pending = 'pending',
    info = 'info',
    error = 'error'
}
