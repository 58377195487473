<!--
 * @Description: 
 * @Author: shilinzhou
 * @Date: 2024-01-24 19:06:55
 * @LastEditors: shilinzhou
 * @LastEditTime: 2024-03-06 15:00:57
-->
<template>
    <div
        ref="wrapperRef"
        class="gantt-blocks"
        :style="{
            height: data.length * cellHeight + 'px'
        }"
    >
        <template v-for="rowItem in data">
            <slot
                name="BlockRow"
                :row-data="rowItem"
                :showList="
                    computedRangeList(
                        rowItem.ganttList
                            ? rowItem.ganttList
                            : rowItem.dataMap
                              ? [].concat(...Object.values(rowItem.dataMap))
                              : []
                    )
                "
            >
            </slot>
        </template>
    </div>
</template>

<script setup lang="ts">
import { type Dayjs } from 'dayjs';
import type { Records, GanttList } from '@/api/plan/gantt/model/type';

const props = defineProps<{
    data: Records[]; // 数据
    cellWidth: number; // 单元格宽度
    cellHeight: number; // 单元格高度
    startTime: Dayjs; // 结束时间
    endTime: Dayjs; // 开始时间
    scale: number; // 时间区域 用来进行缩放
    eRenderTime?: number; //Y轴 开始渲染区域的位置
    sRenderTime?: number; //X轴 开始渲染区域的位置
    scrollTop: number;
    unVisibleH: number;
    wrapperH: number;
    wrapperT: number;
}>();
/**
 * @: 根据时间计算出显示数据
 * @param {*} totalList
 * @return {*}
 */
const computedRangeList = (totalList: GanttList[]) => {
    return totalList.filter(item => {
        return isRender(item.planStartTime, item.planEndTime);
    });
};

/**
 * 判定时间段是否跨越了渲染的时间范围 或者判定时间是否在渲染的时间范围内
 *
 * @returns {boolean}
 * @param timeStart
 * @param timeEnd
 */
const isRender = (timeStart: string, timeEnd: string) => {
    const { sRenderTime, eRenderTime } = props;
    if (sRenderTime !== undefined && eRenderTime !== undefined) {
        const timeStartToMs = new Date(timeStart).getTime();
        const timeEndToMs = new Date(timeEnd).getTime();
        return timeStartToMs <= eRenderTime && timeEndToMs >= sRenderTime;
    }
    return true;
};
</script>

<style scoped lang="scss">
@include b(gantt-blocks) {
    padding: 1px 0;
    cursor: grab;
    background-color: var(--q-bg-color);
}
</style>
