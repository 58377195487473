/*
 * @Description: 骨架屏状态
 * @Author: James324
 * @Date: 2023-08-29 10:39:15
 * @LastEditors: James324
 * @LastEditTime: 2023-08-29 16:33:52
 */
export default defineStore('skeletonStore', () => {
    const loading = ref(false);

    return { loading };
});
