<script setup lang="ts">
import { Result } from '@/service/request/type';
import { excelDownLoad } from '@/utils/excelDownLoad';

const props = defineProps<{
    downloadTemplate: () => Promise<Result>;
}>();

const handleClick = async () => {
    try {
        const data = await props.downloadTemplate();
        excelDownLoad(data);
    } catch (error) {
        console.log(error);
    }
};
</script>
<template>
    <div class="f14">
        <div style="margin-top: 12px">
            <q-icon icon-class="a-218xiazaidaoru1" size="18px"></q-icon>
            请先<span class="link" @click="handleClick">下载导入模板</span> 修改后上传；
            请勿修改模板表格的【字段标题】，防止导入失败
        </div>
        <div class="line">上传的 Excel 表格符合以下规范：</div>
        <ul>
            <li>文件大小不超过20M，且单个sheet页数据量不超过10000行，200列</li>
            <li>仅支持 (*.xls 和 *.xlsx)文件</li>
            <li>请确保您需要导入的sheet表头中不包含空的单元格，否则该sheet页数据系统将不做导入</li>
            <li>导入文件不支持Excel公式计算，如SUM，=H2*J2等</li>
        </ul>
    </div>
</template>

<style scoped lang="scss">
@include b(link) {
    color: var(--q-color-primary);
    cursor: pointer;
}

ul {
    padding-left: 1.5em;
}

ul li {
    line-height: 30px;
    color: var(--q-text-color-secondary);
    list-style: disc;
    opacity: 0.8;
}

.line {
    margin-top: 15px;
    line-height: 30px;
    color: var(--q-text-color-secondary);
    opacity: 0.8;
}
</style>
