/*
 * @Description: 权限指令
 * @Author: James324
 * @Date: 2023-08-21 22:40:14
 * @LastEditors: James324
 * @LastEditTime: 2024-01-11 18:52:22
 */
import { Directive, DirectiveBinding } from 'vue';
import { GlobalStore } from '@/store';

export const permission: Directive = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        const { value } = binding;
        const globalStore = GlobalStore();
        const permissionBtn: string[] = globalStore.buttons || []; // 获取用户所有的权限按钮 ["order:search","feedback:search"]
        const isSuperAdmin = globalStore.isSuperAdmin; // 超管

        // 超管拥有所有按钮权限
        if (isSuperAdmin) return true;

        // 判断用户使用自定义指令，是否使用正确了
        if (value && value instanceof Array) {
            const permissionFunc = value;
            if (!permissionBtn.length) return false;

            // 判断传递进来的按钮权限，用户是否拥有
            const hasPermission = permissionFunc.some((role: any) => {
                return permissionBtn.includes(role);
            });
            if (!hasPermission) {
                el.parentNode && el.parentNode.removeChild(el);
            }
        } else {
            throw new Error("need roles! Like v-has=\"['order:search','feedback:search']\"");
        }
    }
};
