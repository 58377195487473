/*
 * @Description: 国际化 i18n
 * @Author: James324
 * @Date: 2023-08-27 12:59:01
 * @LastEditors: James324
 * @LastEditTime: 2023-12-26 14:49:43
 */
import { STORAGE_PREFIX, HELP_STATE } from '@/config';

export default defineStore(
    'helpStore',
    () => {
        const snackbarContext = reactive({
            dragGuid: false,
            moveGuid: false
        });

        return { snackbarContext };
    },
    {
        persist: {
            key: `${STORAGE_PREFIX}${HELP_STATE}`
        }
    }
);
