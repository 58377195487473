// en.ts
export default {
    login: {
        login: 'login',
        logout: 'logout',
        userName: 'userName',
        password: 'password',
        title: 'Welcome To the System',
        rememberPassword: 'Remember Password',
        forgottenPassword: 'forgotten password',
        rule: {
            userNameMsg: 'Please enter login account',
            userPasswordMsg: 'Please enter login password'
        }
    },
    header: {
        fullScreen: 'FullScreen',
        exitFullScreen: 'Exit FullScreen'
    },
    time: {
        morning: 'Good morning',
        midday: 'Good midday',
        afternoon: 'Good afternoon',
        evening: 'Good evening'
    }
};
