/*
 * @Description: 主题暗黑模式
 * @Author: James324
 * @Date: 2023-08-29 10:39:15
 * @LastEditors: James324
 * @LastEditTime: 2023-09-21 10:21:20
 */
import { STORAGE_PREFIX, DARK_STATE } from '@/config';
import { getDom } from '@/utils';

export default defineStore(
    'darkStore',
    () => {
        const isDark = ref(false);

        const toggleDark = () => {
            isDark.value = !isDark.value;
            initDark();
        };

        const initDark = () => {
            const html = getDom('html');

            if (html) {
                if (!isDark.value) {
                    html.classList.remove('dark');
                    html.classList.add('light');
                } else {
                    html.classList.remove('light');
                    html.classList.add('dark');
                }
            }
        };
        return { isDark, initDark, toggleDark };
    },
    {
        persist: {
            key: `${STORAGE_PREFIX}${DARK_STATE}`
        }
    }
);
