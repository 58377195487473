/*
 * @Description: 国际化 i18n
 * @Author: James324
 * @Date: 2023-08-27 12:59:01
 * @LastEditors: James324
 * @LastEditTime: 2023-08-30 13:30:09
 */
import { STORAGE_PREFIX, LOCAL_STATE } from '@/config';

export default defineStore(
    'localI18nStore',
    () => {
        const local = ref('zh-US');

        return { local };
    },
    {
        persist: {
            key: `${STORAGE_PREFIX}${LOCAL_STATE}`
        }
    }
);
