/*
 * @Description: 标签导航
 * @Author: James324
 * @Date: 2023-08-27 12:59:01
 * @LastEditors: James324
 * @LastEditTime: 2024-01-15 17:38:59
 */
import { STORAGE_PREFIX, TAGS_VIEW_STATE, TagWhiteList } from '@/config';
import { Route } from 'vue-router';

export default defineStore(
    'tagsViewStore',
    () => {
        const excludePaths = ref(['/', '/home']); // 排除加入 tagsView 队列路由地址
        const router = useRouter();
        const route = useRoute();
        const tagsViewList = ref<Array<Route>>([]);
        const activeRoute = ref<Route>();

        /**
         * 当前激活路由
         */
        const setActiveRoute = (route: Route) => {
            activeRoute.value = route;
        };

        /**
         * 添加 tags
         */
        const addTagsViewList = (tag: Route) => {
            // 针对排除队列的路由地址不做处理
            if (excludePaths.value.includes(tag.path!)) return;

            // 处理重复
            const isFindTagsView = tagsViewList.value.find((item, index) => {
                const isSamePath = item.name === tag.name;
                // 重复路由跳转，如详情页，处理 query参数, params参数
                if (isSamePath) {
                    if (item.fullPath !== tag.fullPath) {
                        tagsViewList.value.splice(index, 1, tag);
                    }
                    return true;
                }
            });

            if (!isFindTagsView) {
                tagsViewList.value.push(tag);
                setTagAffix(false);
            }
        };

        /**
         * 删除 tag
         * @param {type: 'other'||'right'||'index', index: index} payload
         */
        const removeTagsView = (payload: any) => {
            if (payload.type === 'index') {
                // 关闭当前
                tagsViewList.value.splice(payload.index, 1);
                setTagAffix(true);
            } else if (payload.type === 'other') {
                // 关闭其它
                tagsViewList.value.splice(
                    payload.index + 1,
                    tagsViewList.value.length - payload.index + 1
                );
                tagsViewList.value.splice(0, payload.index);

                setTagAffix(true);
                redirectTag();
            } else if (payload.type === 'left') {
                // 关闭右侧
                tagsViewList.value.splice(0, payload.index);

                redirectTag();
            } else if (payload.type === 'right') {
                // 关闭右侧
                tagsViewList.value.splice(
                    payload.index + 1,
                    tagsViewList.value.length - payload.index + 1
                );

                redirectTag();
            } else if (payload.type === 'all') {
                // 关闭全部
                if (tagsViewList.value.length === 1) {
                    router.go(0);
                } else {
                    tagsViewList.value = [];
                    router.push('/home');
                }
            }
        };

        /**
         * tagsViewList 列表中移除当前动态路由不匹配的 tagsView
         */
        const removeNoExistsTag = (menus: string[]) => {
            const newTagsViewList: any[] = [];
            tagsViewList.value.forEach(tagsView => {
                if (!tagsView.name) return;
                if (menus.includes(tagsView.path!) || TagWhiteList.includes(tagsView.path!)) {
                    newTagsViewList.push(tagsView);
                }
            });
            tagsViewList.value = newTagsViewList;
        };

        /**
         * 设置当前 tag 是否可关闭
         */
        const setTagAffix = (affix: boolean) => {
            const tagLen = tagsViewList.value.length;

            if (tagsViewList.value[0] && tagsViewList.value[0].meta) {
                tagsViewList.value[0].meta.affix = affix ? tagLen === 1 : !(tagLen > 1);
            }
        };

        /**
         * 关闭当前激活的标签
         */
        const removeActiveTag = () => {
            const index = tagsViewList.value.findIndex(tag => activeRoute.value?.path === tag.path);
            tagsViewList.value.splice(index, 1);
        };

        /**
         * 重定向
         */
        const redirectTag = () => {
            const path = tagsViewList.value[tagsViewList.value.length - 1]['fullPath'];
            if (path !== route.fullPath) {
                router.push(path!);
            }
        };

        return {
            tagsViewList,
            removeTagsView,
            removeActiveTag,
            addTagsViewList,
            activeRoute,
            setActiveRoute,
            excludePaths,
            removeNoExistsTag
        };
    },
    {
        persist: {
            key: `${STORAGE_PREFIX}${TAGS_VIEW_STATE}`,
            paths: ['tagsViewList']
        }
    }
);
