<!--
 * @Description: 表单控件各个选项
 * @Author: James324
 * @Date: 2023-09-28 19:17:16
 * @LastEditors: James324
 * @LastEditTime: 2024-01-25 16:41:09
-->
<script lang="ts">
import { createVNode, defineComponent, renderSlot } from 'vue';
import { generateClassToId } from '@/utils/valid';

export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props, { slots }) {
        return () =>
            createVNode('div', { class: ['q-form-item__content', generateClassToId(props.id)] }, [
                renderSlot(slots, 'default')
            ]);
    }
});
</script>

<style lang="scss" scoped>
@include b(q-form-item) {
    @include e(content) {
        position: relative;
    }
}
</style>
