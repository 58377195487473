/*
 * @Description: 自定义指令
 * @Author: James324
 * @Date: 2023-08-21 22:38:16
 * @LastEditors: James324
 * @LastEditTime: 2023-10-19 19:17:36
 */
import { Directive, App } from 'vue';
import { permission as has } from './permission';
import { scrollbar } from './scrollbar';
import { showtip } from './toolTip';

const directives = {
    has,
    scrollbar,
    showtip
};

export default {
    install(app: App) {
        Object.keys(directives).forEach(key => {
            app.directive(key, (directives as { [key: string]: Directive })[key]);
        });
    }
};
