// zh.ts
export default {
    login: {
        login: '登录',
        logout: '退出登录',
        userName: '用户名',
        password: '密码',
        title: '灯塔APS高级计划排程系统',
        rememberPassword: '记住密码',
        forgottenPassword: '忘记密码',
        rule: {
            userNameMsg: '请输入登录账号',
            userPasswordMsg: '请输入登录密码'
        }
    },
    header: {
        fullScreen: '全屏',
        exitFullScreen: '退出全屏'
    },
    time: {
        morning: '早上好',
        midday: '中午好',
        afternoon: '下午好',
        evening: '晚上好'
    }
};
