import { LocalStorage } from './storage';
import { GlobalStore } from '@/store';
import { GLOBAL_STATE, TAGS_VIEW_STATE, WhiteList } from '@/config';
import type { Route } from 'vue-router';

/**
 * @description: 获取用户信息
 * @return {*}
 */
export const getUserInfo = () => LocalStorage.get(GLOBAL_STATE)?.userInfo;

/**
 * @description: 获取 token
 * @return {*}
 */
export const getToken = () => GlobalStore().token;

/**
 * @description: 移除 token
 * @return {*} void
 */
export const removeToken = () => {
    const globalStore = GlobalStore();
    const TagsViewStore = tagsViewStore();
    const SideMenuStore = sideMenuStore();

    globalStore.$reset();
    SideMenuStore.subMenu = {};
    TagsViewStore.tagsViewList = []; // 重置路由标签
    setTimeout(() => {
        LocalStorage.remove(GLOBAL_STATE); // 异步清除除 LOGIN_LOGIN_FORM 外全局状态
        LocalStorage.remove(TAGS_VIEW_STATE);
    }, 0);
};

/**
 * @: 按钮权限函数
 * @param {string} permissions
 * @return {*}
 */
export const has = (permissions: string[]) => {
    const globalStore = GlobalStore();
    const permissionBtn: string[] = globalStore.buttons || []; // 获取用户所有的权限按钮 ["order/search","feedback/search"]
    const isSuperAdmin = globalStore.isSuperAdmin; // 超管

    // 超管拥有所有按钮权限
    if (isSuperAdmin) return true;

    // 判断传递进来的按钮权限，用户是否拥有
    return permissionBtn.some((role: any) => {
        return permissions.includes(role);
    });
};

/**
 * @：菜单权限函数
 * @param {string} menu 菜单路由
 */
export const hasMenu = (menu: string) => {
    const globalStore = GlobalStore();
    const menus: string[] = globalStore.userInfo.menus || []; // 获取用户所有的菜单权限列表，如 ["/modeling"]
    const isSuperAdmin = globalStore.isSuperAdmin; // 超管

    // 超管拥有所有按钮权限
    if (isSuperAdmin) return true;

    // 判断传递进来的菜单路由地址，用户是否拥有
    return menus.includes(menu);
};

export const getRoleTotal = (permissions: string[]) => {
    const globalStore = GlobalStore();
    const permissionBtn: string[] = globalStore.buttons || []; // 获取用户所有的权限按钮 ["order/search","feedback/search"]
    const isSuperAdmin = globalStore.isSuperAdmin; // 超管

    // 如果是超管返回所有的索引
    if (isSuperAdmin) return permissions.map((_, i) => i);

    // 所有的权限标识
    const roleTotalArr: number[] = [];

    // 处理权限标识
    permissions.forEach((ite, ind) => {
        if (Array.isArray(ite)) {
            ite.forEach(item => {
                if (!roleTotalArr.includes(ind) && permissionBtn.includes(item))
                    roleTotalArr.push(ind);
            });
        } else {
            if (permissionBtn.includes(ite)) roleTotalArr.push(ind);
        }
    });
    return roleTotalArr;
};
/**
 * 设置路由 name 集合
 */
export const findNames = (Routes: Route[]) => {
    const result: string[] = [];
    function traverse(node: Route) {
        if (
            WhiteList.includes(node.path!) ||
            !node.name ||
            !node.path ||
            result.includes(node.name as string)
        )
            return;
        if (node.redirect) {
            if (node.redirect === node.path && node.children) {
                result.push(node.children[0].name as string);
            }
        } else {
            result.push(node.name as string);
        }

        if (node.children) node.children.forEach(traverse);
    }
    Routes.forEach(traverse);
    return result;
};
