import { generateNameToLevel, mutationConfig } from '@/config/constant';
import { getDom } from '@/utils';

import type { TableRefs } from 'element-plus';

export default (
    defaultHeight: (contentMain?: HTMLElement | null) => void,
    domRef?: Ref<TableRefs | undefined>,
    eleRef?: Ref<HTMLDivElement | undefined>
) => {
    // 防抖
    const debounceHeight = useDebounceFn(defaultHeight, 200);

    // 最外层dom
    const contentMain = ref<HTMLElement | null>(null);

    onMounted(() => {
        contentMain.value = getDom(`#${generateNameToLevel('l-content--main')}`);

        mutationTable();
        /**
         * @: 监听dom变换计算高度
         * @return {*}
         */
        useResizeObserver(contentMain, () => {
            debounceHeight(contentMain.value);
        });
    });

    /**
     * @: 监听dom渲染完成计算高度
     * @return {*}
     */
    const mutationTable = () => {
        const dom = domRef?.value?.$el || eleRef?.value;

        useMutationObserver(
            dom,
            () => {
                debounceHeight(contentMain.value?.parentElement);
            },
            mutationConfig
        );
    };
};
