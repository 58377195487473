import type { TableColumnConfig } from '../type';
export default (
    orderSort: Ref<anyObj>,
    getData: (params?: anyObj | undefined) => Promise<unknown>,
    emit: any
) => {
    /**
     * @: 排序相关
     */
    const ascending = ref(false);
    const descending = ref(false);
    const currentSort = ref('');
    const handleSortChange = (item: TableColumnConfig, order: 'ascending' | 'descending') => {
        // 判断本次点击按钮所在的单元格是否和上次是同一个
        if (item.sortUrlKey !== currentSort.value) {
            ascending.value = false;
            descending.value = false;
        }
        // 如果点击升序 把倒序归为false并取反本次点击
        if (order === 'ascending') {
            ascending.value = !ascending.value;
            descending.value = false;
        } else {
            descending.value = !descending.value;
            ascending.value = false;
        }

        currentSort.value = item.sortUrlKey;

        // 如果时自定义排序把数据传递出去
        if (item.sortItem === 'custom') {
            emit('sortChange', item, order);
        } else {
            if (descending.value || ascending.value) {
                orderSort.value = {
                    orders: [{ column: item.sortUrlKey, asc: order === 'ascending' }]
                };
            } else {
                orderSort.value = {};
            }
            getData();
        }
    };
    return {
        handleSortChange,
        currentSort,
        descending,
        ascending
    };
};
