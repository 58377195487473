/**
 * 横杠转小驼峰
 * @param {string} str
 * @return {*}
 */
export function toCamelCase(str: string) {
    return str.replace(/[-_]+([a-z])/g, (_, letter) => letter.toUpperCase());
}

/**
 * 横杠转大驼峰
 * @param {string} str
 * @return {*}
 */
export function toPascalCase(str: string) {
    const camelCase = toCamelCase(str);
    return camelCase.charAt(0).toUpperCase() + camelCase.slice(1);
}
