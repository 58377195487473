import { AxiosResponse } from 'axios';
import { Result } from '../request/type';
import { message } from '@/components/q-message/message';
import { removeToken, toLogin } from '@/utils';
import { GlobalStore } from '@/store';
import { LocalStorage } from '@/utils/storage';
import { GLOBAL_STATE } from '@/config';
import { login } from '@/api/login/index';
import QAxios from '../index';

/**
 * Excel 请求处理
 */
export function handleExcelRes(res: AxiosResponse<Result>) {
    const header: string = res.headers['content-disposition'];
    if (header) {
        const name = header.split(';')[1];
        res.data.name = decodeURIComponent(name.split('=')[1]);
    }
    return new Promise((resolve, reject) => {
        if (!res.data || res.data.type === 'application/json') {
            try {
                const reader = new FileReader();
                reader.readAsText(res.data as any, 'utf-8');
                reader.onload = function () {
                    const data = JSON.parse(reader.result as string);
                    message.error(data.msg || '系统出错！');
                    reject(res);
                };
            } catch (error: any) {
                message.error(error.data.msg || '系统出错！');
                reject(res);
            }
        }
        resolve(res);
    });
}

/**
 * 处理后端返回对应 code 编码对应逻辑
 */
class Func {
    // 请求成功
    static success(res: AxiosResponse<any>) {
        return Promise.resolve(res);
    }

    // 登录失效
    static noLogin(res: AxiosResponse<any>) {
        removeToken();
        toLogin();

        let errMsg = '';
        switch (res.data.code) {
            case 2:
                errMsg = '请进行登录';
                break;
            case 3:
            case 5:
            case 6:
                errMsg = '请重新登录';
                break;
            case 8:
                errMsg = '密码已更新，请重新登录';
                break;
            case 10:
                errMsg = '当前账户已在其它地方登录，请重新登录';
                break;
            case 23:
                errMsg = '用户没有任何权限，请联系管理员配置权限';
                break;
            default:
        }

        return message.error(errMsg || res.data.msg || '登录失效，请重新登录！');
    }

    // 未授权
    static noAuth(res: AxiosResponse<any>) {
        message.error('暂无权限，请联系管理员进行配置！');
        return Promise.reject(res);
    }

    // 无感刷新 token
    static refreshToken(res: AxiosResponse<any>) {
        try {
            if (!isRefreshing) {
                setRefreshStatus(true);
                refreshToken();
            }
            return new Promise(resolve => {
                reqQueue.push((token: string) => {
                    res.config.headers['Authorization'] = token;
                    resolve(QAxios.instance.request(res.config));
                });
            });
        } catch (error) {
            // 未授权
            Func.noLogin(res);
            return Promise.reject(res);
        }
    }
}

/**
 * @: 处理请求参数中的空字符串
 * @param {any} data
 * @return {*}
 */
export const removeEmptyAttr = (data: any): any | null => {
    if (data === null || data === '') {
        return null;
    }
    if (typeof data !== 'object') {
        return data;
    }
    if (Array.isArray(data)) {
        for (let index = 0; index < data.length; index++) {
            let value = data[index];
            if (typeof value === 'string' || typeof value === 'object') {
                value = removeEmptyAttr(value);
            }
            value == null && data.splice(index--, 1);
        }
    } else {
        for (const key in data) {
            let value = data[key];
            if (typeof value === 'string' || typeof value === 'object') {
                value = removeEmptyAttr(value);
            }
            value == null && delete data[key];
        }
    }
    return data;
};

interface CodeCollection {
    [key: number]: any;
}
export const codeCollection: CodeCollection = {
    200: Func.success, // 成功
    2: Func.noLogin, // 未登录
    3: Func.noLogin, // token失效
    4: Func.refreshToken, // 无感刷新 token
    5: Func.noLogin, // 刷新 token 不可用
    6: Func.noLogin, //  刷新 Token 已过期，重新登录
    7: Func.noAuth, // 未授权, 权限不足拒绝访问
    8: Func.noLogin, // 账号资料已过期，需要重新登录（包括重置密码登）
    10: Func.noLogin, // Token 发生变化，已在其他地方登录
    23: Func.noLogin, // 未被分配任何权限，请管理员配置权限
    14: Func.noAuth // 未授权, 权限不足拒绝访问
};

/**
 * 定义当前刷新状态
 */
export let isRefreshing = false;
export const getRefreshStatus = () => isRefreshing;
export const setRefreshStatus = (val: boolean) => (isRefreshing = val);

/**
 * 当前请求队列
 */
type Cb = (token: string) => void;
export let reqQueue: Cb[] = [];
export const addReq = (cb: Cb) => reqQueue.push(cb);

/**
 * 刷新当前 token
 */
export const refreshToken = async () => {
    const globalStore = GlobalStore();
    const { tokenInfo } = LocalStorage.get(GLOBAL_STATE);
    try {
        setRefreshStatus(true);
        const res = await login({ value: tokenInfo.refreshToken.value }, 'refresh_token');
        if (res && res.data) {
            const data = res.data;
            globalStore.setTokenInfo(data);
            globalStore.setToken(data.value);
            setRefreshStatus(false);
            reqQueue.forEach(cb => cb(data.value));
            reqQueue = [];
        }
    } catch (error) {
        return Promise.reject(error);
    }
    setRefreshStatus(false);
};
