import { has, getRoleTotal } from '@/utils';
import type { TableColumnConfig } from '../type';

export default (tableConfig: TableColumnConfig, filterLabel: (label: string) => boolean) => {
    /**
     * @: 根据权限判断操作的宽度
     */
    const ActionWidth = computed(() => {
        const { allHas, hasWidth, editHas, delHas } = tableConfig;
        let hasArr: string[] = [];
        if (editHas) hasArr = [...hasArr, ...editHas];
        if (delHas) hasArr = [...hasArr, ...delHas];

        // 获取所有有权限的数组
        const roleArr = getRoleTotal(allHas || hasArr);
        // 进行权限的判断
        const total = roleArr.reduce((total, item, index) => {
            const width = hasWidth?.[item] || 28;
            if (index === 0) {
                return total + width;
            }
            return total + width + 24;
        }, 30);
        return total;
    });

    /**
     * @: 判断出是否应该显示操作栏
     */
    const filterAction = computed(() => {
        const { allHas, editHas, delHas, unAction } = tableConfig;

        // 权限数组
        let hasArr: string[] = [];
        // 如果权限把权限放进权限数组中
        if (editHas) hasArr = [...hasArr, ...editHas];
        if (delHas) hasArr = [...hasArr, ...delHas];
        // 如果有全部权限就用全部权限  如果没有全部权限就只使用编辑和删除权限
        return !unAction && filterLabel('操作') && has(allHas?.flat() || hasArr);
    });

    return { filterAction, ActionWidth };
};
