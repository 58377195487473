<script setup lang="ts">
const emit = defineEmits(['sorting']);
</script>
<template>
    <div class="caret-wrapper">
        <div class="icon-parent" @click.stop="emit('sorting', 'ascending')">
            <i class="sort-caret ascending"></i>
        </div>
        <div class="icon-parent" @click.stop="emit('sorting', 'descending')">
            <i class="sort-caret descending"></i>
        </div>
    </div>
</template>

<style scoped lang="scss">
@include b(icon-parent) {
    width: 100%;
    height: 12px;
    cursor: pointer;
}
</style>
