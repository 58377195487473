/*
 * @Description: Modal 类型
 * @Author: James324
 * @Date: 2023-09-10 11:44:50
 * @LastEditors: James324
 * @LastEditTime: 2024-01-09 10:26:22
 */

import { AppContext, VNode, CSSProperties } from 'vue';

type VnodeType = () => string | VNode;
export interface ModalFuncProps {
    title?: string | VnodeType;
    content?: string | VnodeType | VNode;
    width?: string;
    visible?: boolean;
    type?: string;
    centered?: boolean;
    style?: CSSProperties;
    okType?: string;
    bodyHeight?: string;
    wrapClassName?: string;
    okText?: string | VnodeType;
    cancelText?: string | VnodeType;
    confirmLoading?: boolean;
    closable?: boolean;
    okCancel?: string | VnodeType | boolean;
    okButtonProps?: Record<string, any>;
    cancelButtonProps?: Record<string, any>;
    parentContext?: AppContext;
    appContext?: AppContext;
    afterClose?: () => void;
    onOk?: (...arg: any[]) => void | Promise<any>;
    onCancel?: (...arg: any[]) => void | Promise<any>;
}

export default () => {
    const withWarn = (props: ModalFuncProps): ModalFuncProps => {
        return {
            ...props,
            type: 'warning'
        };
    };

    const withInfo = (props: ModalFuncProps): ModalFuncProps => {
        return {
            ...props,
            type: 'info'
        };
    };

    const withSuccess = (props: ModalFuncProps): ModalFuncProps => {
        return {
            ...props,
            type: 'success'
        };
    };

    const withError = (props: ModalFuncProps): ModalFuncProps => {
        return {
            ...props,
            type: 'error'
        };
    };

    const withConfirm = (props: ModalFuncProps): ModalFuncProps => {
        return {
            width: '351px',
            ...props,
            type: 'confirm'
        };
    };

    return { withWarn, withInfo, withSuccess, withError, withConfirm };
};
