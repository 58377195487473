import QsRequest from '@/service';
import { LoginRequest, RefreshTokenRequest, UserInfoResponse } from './model/type';

/**
 * @description: 登录
 * @param {UserInfoRequest} data
 * @return {*}
 */
export const login = (
    data: LoginRequest | RefreshTokenRequest,
    grant_type = 'username_password'
) => {
    return QsRequest.post<UserInfoResponse>(`/login`, data, {
        params: { grant_type },
        headers: { hideLoading: 1, hideMsg: 1 }
    });
};

/**
 * @description: 退出登录
 * @return {*}
 */
export const logout = (token: string) => {
    return QsRequest.post(`/logout`, null, {
        headers: { hideLoading: 1, Authorization: token }
    });
};

/**
 * @description: 校验手机号是否有权限
 * @param { phone: string } params
 * @return {*}
 */
export const checkPhone = (params: { phone: string }) => {
    return QsRequest.get(`/system/user/hasPermission`, {
        params,
        headers: { hideLoading: 1, hideMsg: 1 }
    });
};
