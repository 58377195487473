<!--
 * @Description: 
 * @Author: shilinzhou
 * @Date: 2024-01-24 19:06:55
 * @LastEditors: shilinzhou
 * @LastEditTime: 2024-03-15 16:52:04
-->
<template>
    <div
        v-show="!rowItem.hideRow"
        id="gantt-row"
        :class="['el-row']"
        :style="{
            top: rowItem.rowIndex * cellHeight + 'px',
            height: `${cellHeight}px`
        }"
    >
        <slot :colList="showColList" name="colList">
            <gantt-col
                v-for="(col, index) in showColList"
                :key="index"
                :col="col"
                :rowItem="rowItem"
                :index="index"
                :get-position-offset="getPositionOffset"
                :get-scope-width="getScopeWidth"
                @dragover.prevent
                @mousedown.left.stop="handleLeftClick($event, col)"
                @mousedown.right.stop="handleRightClick"
            />
        </slot>
    </div>
</template>

<script setup lang="ts">
import ganttCol from './gantt-col.vue';
import type { Records, GanttList } from '@/api/plan/gantt/model/type';

const props = defineProps<{
    rowItem: Records;
    getPositionOffset: (date: string) => number;
    getScopeWidth: (start: string, end: string) => number;
    showColList: GanttList[];
    cellHeight: number;
}>();
const emit = defineEmits(['getCurrentItem']);

/**
 * @: 鼠标左键 缓存当前点击行和列
 * @param {*} col
 * @return {*}
 */
const handleLeftClick = (e: MouseEvent, col: any) => {
    emit('getCurrentItem', { e, row: props.rowItem, col });
};
/**
 * @: 鼠标右键
 * @param {*} col
 * @return {*}
 */
const handleRightClick = (e: Event) => {
    console.log('e', e);
};
</script>

<style scoped lang="scss">
.el-row {
    position: absolute;
    width: 100%;
    padding: 5px 0;
}
</style>
