import { Result } from '@/service/request/type';
import { AxiosProgressEvent } from 'axios';
export default () => {
    const percentage = ref(0);

    const upload = async (
        file: File,
        uploadData: { isUpdate: boolean },
        uploadFn: (
            params: any,
            uploadData: { isUpdate: boolean },
            onUploadProgress?: (e: AxiosProgressEvent) => void
        ) => Promise<Result>
    ) => {
        // const fileSize = file.size; // 文件大小
        // if (fileSize > 1024 * 1024 * 2) {
        //     const data = await bigUpload(file, uploadFn);
        //     percentage.value = 0;
        //     return data;
        // } else {
        const data = await minUpload(file, uploadData, uploadFn);
        percentage.value = 0;
        return data;
        // }
    };

    const minUpload = async (
        file: File,
        uploadData: { isUpdate: boolean },
        uploadFn: (
            params: any,
            uploadData: { isUpdate: boolean },
            onUploadProgress?: (e: AxiosProgressEvent) => void
        ) => Promise<Result>
    ) => {
        const formData = new FormData();
        formData.append('file', file);
        const data = await uploadFn(formData, uploadData, (e: AxiosProgressEvent) => {
            percentage.value = Math.floor((e.loaded / (e.total || 0)) * 100);
        });
        return data;
    };

    // const bigUpload = async (file: File, uploadFn: (params: any) => Promise<Result>) => {
    //     const fileSize = file.size; // 文件大小
    //     const chunkSize = 1024 * 1024; // 每个块的大小为 1MB
    //     const chunks = Math.ceil(fileSize / chunkSize); // 总块数
    //     const tasks = []; // 上传任务数组
    //     let uploaded = 0; // 已上传块数

    //     // 文件切割
    //     for (let i = 0; i < chunks; i++) {
    //         const start = i * chunkSize;
    //         const end = Math.min(start + chunkSize, fileSize);

    //         tasks.push(
    //             new Promise((resolve, reject) => {
    //                 const formData = new FormData();
    //                 formData.append('chunk_index', i + ''); // 块编号
    //                 formData.append('chunk_count', chunks + ''); // 总块数
    //                 formData.append('file_id', file.name + i); // 文件ID
    //                 formData.append('chunk_data', file.slice(start, end)); // 块数据

    //                 uploadFn(formData) // 上传块数据
    //                     .then(res => {
    //                         uploaded++;
    //                         percentage.value = Math.floor((uploaded / chunks) * 100);
    //                         resolve(res);
    //                     })
    //                     .catch(err => {
    //                         reject(err);
    //                     });
    //             })
    //         );
    //     }

    //     // 待所有块上传完成后，发送合并请求
    //     await Promise.all(tasks);

    //     const data = await uploadFn({ file_id: file.name, chunks });
    //     percentage.value = 0;
    //     return data;
    // };
    return { percentage, upload };
};
