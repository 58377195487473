<!--
 * @description: SVG Icon
 * @Author: vikingShip
 * @Date: 2022-05-13 16:27:53
 * @LastEditors: James324
 * @LastEditTime: 2023-12-23 16:58:16
-->
<script setup lang="ts">
const $props = defineProps({
    iconClass: {
        type: String,
        required: true
    },
    className: {
        type: String,
        default: ''
    },
    color: {
        type: String,
        default: ''
    },
    size: {
        type: String,
        default: '24px'
    }
});
const $emit = defineEmits(['click']);
const iconName = computed(() => `#icon-${$props.iconClass}`);
const svgClass = computed(() => {
    if ($props.className) {
        return 'svg-icon ' + $props.className;
    } else {
        return 'svg-icon';
    }
});
</script>

<template>
    <svg
        :class="svgClass"
        :style="{ color: color, 'font-size': size }"
        aria-hidden="true"
        fill="#f00"
        @click="$emit('click', $event)"
        @dblclick.prevent
    >
        <use :xlink:href="iconName"></use>
    </svg>
</template>

<style lang="scss" scoped>
.svg-icon {
    width: 1em;
    height: 1em;
    overflow: hidden;
    color: inherit;
    vertical-align: -0.15em;
    fill: currentcolor;
}
</style>
