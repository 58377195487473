import { Records, GanttList } from '@/api/plan/gantt/model/type';

export default (
    handleMapTime: (xStart: number) => string,
    GData: Ref<Records[]>,
    init: () => void
) => {
    let currentCol: GanttList; // 当前点击col
    let currentRow: Records; //当前点击row
    let cursorOffsetX: number; // 鼠标与当前点击块的距离
    let colWidth: number; // col的宽度
    let colEle: HTMLDivElement; // col元素
    /**
     * @: 获取当前点击的col和row
     * @param {object} item
     * @return {*}
     */
    const getCurrentItem = (item: { e: MouseEvent; col: GanttList; row: Records }) => {
        const { col, row, e } = item;

        colEle = e.target as HTMLDivElement; // col元素
        const clientRect = colEle.getBoundingClientRect(); // col元素位置
        cursorOffsetX = e.clientX - clientRect.left;
        colWidth = clientRect.width; //
        currentCol = col;
        currentRow = row;
    };

    /**
     * @: 拖动到当前row
     * @param {DragEvent} e
     * @param {IData} rowData
     * @return {*}
     */
    const dropToRow = (e: DragEvent, rowData: Records) => {
        let ele: HTMLDivElement = e.target as HTMLDivElement;
        if (ele === colEle && ele.parentNode) {
            ele = ele.parentNode as HTMLDivElement;
        }

        if (ele.id !== 'gantt-row') return;
        const clientRect = ele.getBoundingClientRect();

        const xStart = e.clientX - clientRect.left - cursorOffsetX;
        const xEnd = xStart + Math.ceil(colWidth);
        removeCol(currentRow.deviceId, currentCol);
        addCol(xStart, xEnd, rowData.deviceId, currentCol);
    };

    // 删除col
    const removeCol = (sourceId: number | undefined, target: GanttList | undefined) => {
        const source = ganttData.value?.find(item => item.deviceId === sourceId);
        if (!source) return;
        source.ganttList = source.ganttList.filter(item => item !== target);
    };

    // 新增col
    const addCol = (
        xStart: number,
        xEnd: number,
        sourceId: number,
        target: GanttList | undefined
    ) => {
        if (!target) return;
        const planStartTime = handleMapTime(xStart);
        const planEndTime = handleMapTime(xEnd);
        const source = ganttData.value?.find(item => item.deviceId === sourceId);
        const newTarget = { ...target, planStartTime, planEndTime };
        source?.ganttList.push(newTarget);
    };

    const ganttData = computed(() => {
        return GData.value;
    });

    watch(
        () => GData.value.length,
        () => {
            if (GData.value.length) {
                init();
            }
        }
    );

    /**
     * @: 鼠标左键 缓存当前点击行和列
     * @param {*} col
     * @return {*}
     */
    const leftClick = (e: MouseEvent, col: GanttList, rowItem: Records) => {
        getCurrentItem({ e, row: rowItem, col });
    };

    return {
        getCurrentItem,
        dropToRow,
        ganttData,
        leftClick
    };
};
