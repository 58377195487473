<script setup lang="ts">
const triggerType = {
    CLICK: 'click',
    FOCUS: 'focus',
    HOVER: 'hover',
    CONTEXTMENU: 'contextmenu'
} as const;

type triggerKeyType = typeof triggerType;
type triggerKey = keyof triggerKeyType;
type triggerValue = triggerKeyType[triggerKey];

interface DefineProps {
    visible?: boolean;
    placement?: string;
    width?: string | number;
    offset?: number;
    trigger?: triggerValue;
    title?: string;
    zIndex?: number;
}

withDefaults(defineProps<DefineProps>(), {
    width: 220,
    placement: 'bottom-end',
    trigger: 'click',
    title: '您确认删除这条数据吗？',
    visible: false,
    zIndex: 12
});

const emit = defineEmits(['update:visible', 'ok']);

const handleOk = () => {
    emit('ok');
    popoverVisible.value = false;
};

const popoverVisible = ref(false);
</script>

<template>
    <el-popover
        v-model:visible="popoverVisible"
        :placement="placement"
        :width="width"
        :offset="offset"
        :trigger="trigger"
        :popper-style="{ 'z-index': zIndex }"
        v-bind="$attrs"
    >
        <template #default>
            <p class="m-table__popover">
                <q-icon icon-class="warning" size="18px" style="margin-right: 4px" />
                {{ title }}
            </p>
            <div class="m-table__popover--btns">
                <el-button size="small" @click.stop="popoverVisible = false">取消</el-button>
                <el-button size="small" type="primary" @click="handleOk">确定</el-button>
            </div>
        </template>
        <template #reference>
            <slot></slot>
        </template>
    </el-popover>
</template>

<style lang="scss" scoped>
@include b(m-table) {
    @include e(popover) {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 24px;

        @include m(btns) {
            margin: 0;
            text-align: right;
        }
    }
}
</style>
