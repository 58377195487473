import QsRequest from '@/service';
import { UserInfo, UpdatePassword } from './model/type';

export const getUserInfo = () => {
    return QsRequest.get<UserInfo>(`/system/user/getCurrentUserInfo`, {
        headers: { hideLoading: 1 }
    });
};

export const updatePassword = (data: UpdatePassword) => {
    return QsRequest.post<UserInfo>(`/system/user/updatePassword`, data, {
        headers: { hideLoading: 1 }
    });
};
