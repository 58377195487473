<!--
 * @Description: 
 * @Author: shilinzhou
 * @Date: 2024-01-24 19:06:55
 * @LastEditors: shilinzhou
 * @LastEditTime: 2024-03-18 15:41:58
-->
<template>
    <div
        id="gantt-col"
        :draggable="draggable"
        class="el-col"
        :style="{
            left: positionOffset + 'px',
            width: blockWidth + 'px',
            backgroundColor: generateLightColor(col[dataClor].toString())
        }"
        @pointerdown.stop
        @contextmenu.stop
    >
        <slot name="col" :colData="col"> </slot>
    </div>
</template>

<script setup lang="ts">
import type { Records, GanttList } from '@/api/plan/gantt/model/type';
import { generateLightColor } from '@/utils';

const props = defineProps<{
    col: GanttList;
    dataClor: string;
    rowItem: Records;
    index: number;
    getPositionOffset: (date: string) => number;
    getScopeWidth: (start: string, end: string) => number;
}>();

// 是否拖动
const draggable = false;
const positionOffset = computed(() => {
    const { col, getPositionOffset } = props;
    return getPositionOffset(col.planStartTime);
});

const blockWidth = computed(() => {
    const {
        col: { planStartTime, planEndTime },
        getScopeWidth
    } = props;
    const width = getScopeWidth(planStartTime, planEndTime);
    return width;
});
</script>

<style scoped lang="scss">
.el-col {
    position: absolute;
    height: 40px;
    line-height: 40px;
    color: #595959;
    text-align: center;
    cursor: default;
    border-right: 1px solid transparent;

    @include utils-ellipsis(1);
}
</style>
