/*
 * @Description: 计算表格默认最小高度
 * @Author: James324
 * @Date: 2023-09-26 18:50:11
 * @LastEditors: James324
 * @LastEditTime: 2024-01-12 10:44:51
 */
import { toPx, getDom } from '@/utils';
import useTableHeight from '@/hooks/useTableHeight';

import type { TableHeight } from '../type';
import type { TableRefs } from 'element-plus';

export default (props: TableHeight, domRef: Ref<TableRefs | undefined>) => {
    const minHeight = ref<string | number>(0);

    const actionRef = ref<any[]>([]);

    // 默认组件高度处理方式
    const defaultHeight = (scrollElement?: HTMLElement | null) => {
        const wHeight = window.innerHeight; // 可视窗口高度
        const elRef = domRef?.value?.$el;
        const elTop = useElementBounding(elRef);
        if (!props.addTableRow && !props.modalMode) {
            const pageSpace = 48 + 38; // .m-table 离底部距离
            const paginationDom: HTMLElement | null = getDom('.m-table__pagination');
            const paginationHeight = paginationDom?.scrollHeight || 0; // 分页高度
            const scrollTop = scrollElement?.scrollTop || 0; // 滚动高度
            const top = elTop.top.value + scrollTop; // 距离顶部的高度

            const elHeightArr = [top, paginationHeight, pageSpace];

            minHeight.value = elHeightArr.reduce((total, height) => total - height, wHeight);
        } else {
            // 模态框的状态下
            if (props.maxHeight) {
                const pageSpace = 44; // .m-table 离底部距离
                const takeUnit = +props.maxHeight();

                minHeight.value = toPx(takeUnit - pageSpace);
            } else {
                minHeight.value = '';
            }
        }

        useResizeObserver(actionRef.value?.[0], handleActionWidth);
    };

    // 统一计算高度
    useTableHeight(defaultHeight, domRef);

    // 计算操作栏的宽度
    const actionWidth = ref(108);
    const handleActionWidth = () => {
        // 计算宽度
        actionRef.value.forEach(item => {
            const node: HTMLElement[] = item?.children;
            if (!node?.[0]) return;
            node[0].style.marginLeft = '0px';
            node[0].style.paddingLeft = '0px';
        });
    };

    return { minHeight, actionRef, actionWidth };
};
