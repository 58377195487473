import { STORAGE_PREFIX } from '@/config';
type Storage = typeof sessionStorage | typeof localStorage;

/**
 * @description: storage 工具方法
 * @param {Storage} 状态存储对象
 * @return {Object}
 */
const useStorage = ($storage: Storage) => {
    /**
     * 根据 key 值获取储存在 storage 中的值
     * @param key storage key
     */
    const get = (key: string) => {
        try {
            return JSON.parse($storage.getItem(`${STORAGE_PREFIX}${key}`) as any);
        } catch {
            return null;
        }
    };

    /**
     * 根据 key 值向 storage 中储存值
     * @param key storage key
     * @param value 需要储存在 storage 中的值
     */
    const set = (key: string, value: any) => {
        return $storage.setItem(`${STORAGE_PREFIX}${key}`, value ? JSON.stringify(value) : value);
    };

    /**
     * 根据 key 值移除储存在 storage 中的值
     * @param key storage key
     */
    const remove = (key: string) => {
        return $storage.removeItem(`${STORAGE_PREFIX}${key}`);
    };

    /**
     * 移除除了 key 之外的所有储存在 storage 中的值
     * @param key storage key
     */
    const clearExcept = (key: string) => {
        for (let i = 0; i < $storage.length; i++) {
            const itemKey: string | undefined | null = $storage.key(i);
            if (itemKey && itemKey !== `${STORAGE_PREFIX}${key}`) {
                $storage.removeItem(itemKey);
            }
        }
    };

    /**
     * 移除所有储存在 storage 中的值
     */
    const clearAll = () => {
        for (const itemKey in $storage) {
            if (itemKey) {
                $storage.removeItem(itemKey);
            }
        }
    };

    return {
        get,
        set,
        remove,
        clearExcept,
        clearAll
    };
};

export const SessionStorage = useStorage(window.sessionStorage || sessionStorage);
export const LocalStorage = useStorage(window.localStorage || localStorage);
