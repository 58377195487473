import router from '@/router';
import { resetRouter } from '@/router/helper/handle';
import type { RouteLocationNormalizedLoaded } from 'vue-router';

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
export const toLogin = () => {
    const route = router.currentRoute.value;
    const noRedirect = ['/login'];
    router.replace({
        path: '/login',
        query: noRedirect.some(item => route.fullPath.includes(item))
            ? {}
            : {
                  redirect: route.fullPath || '/'
              }
    });
    resetRouter(); // 重置动态路由
};

/**
 * 获取页面标题
 */
export const getTitle = (route: RouteLocationNormalizedLoaded) => {
    return route.meta.title as string;
};
