<script setup lang="ts">
import ImportBottom from './components/bottom.vue';
import ImportTip from './components/tip.vue';
import { Result } from '@/service/request/type';
import { removePending } from '@/service/request/cancelToken';
import { AxiosProgressEvent } from 'axios';
import Modal from '../q-modal/index.vue';

const props = defineProps<{
    uploadFn: (
        params: any,
        uploadData: { isUpdate: boolean },
        onUploadProgress?: (e: AxiosProgressEvent) => void
    ) => Promise<Result>;
    url: string;
    downloadTemplate: () => Promise<Result>;
    hideUpdateBtn?: boolean;
    tableRef?: TableRef;
}>();

const fileInput = ref<HTMLInputElement>();
// 模拟点击
const handleImport = () => {
    fileInput.value?.click();
};

// 点击上传
const handleSubmit = async () => {
    const file = fileInput.value?.files?.[0];
    await handleUploadChange(file);
};

// 拖拽上传
const onDrop = (e: DragEvent) => {
    e.stopPropagation();
    if (show.value.uploadStatus === 'uploading') {
        return;
    }
    show.value.dragover = false;
    const files = Array.from(e.dataTransfer!.files)[0];
    handleUploadChange(files);
};

const uploadName = ref('');
const tipContent = ref('');
const tipType = ref<'success' | 'error'>('success');
const { upload, percentage } = useUpload();

const init = () => ({
    dragover: false,
    uploadStatus: 'beforeUpload',
    tip: false,
    radio: true
});
// 显隐状态
const show = ref(init());
let cancelFile: File;

// 上传函数
const handleUploadChange = async (file?: File) => {
    if (!file) return;
    show.value.radio = false; // 单选框显示
    show.value.tip = false; // 每次上传前先清除上次提示框

    cancelFile = file;
    uploadName.value = file.name;
    const arr = file.name.split('.');
    const suffix = arr[arr.length - 1];

    if (suffix === 'xlsx' || suffix === 'xls') {
        try {
            show.value.uploadStatus = 'uploading'; // 当前上传的状态
            await upload(file, { isUpdate: radio.value === 'update' }, props.uploadFn);
            tipContent.value = `成功导入数据`;
            show.value.uploadStatus = 'uploaded';
            tipType.value = 'success';
            props.tableRef?.getData?.();
        } catch (error: any) {
            tipType.value = 'error';
            tipContent.value = error.data?.msg || '系统出错';
            show.value.uploadStatus = 'beforeUpload';
        }
    } else {
        tipContent.value = '上传文件格式不正确！';
        show.value.uploadStatus = 'beforeUpload';
        tipType.value = 'error';
    }

    show.value.tip = true;
    if (fileInput.value) {
        fileInput.value.value = '';
    }
};

const importVisible = ref(false);
// 关闭弹框
const handleClose = () => {
    if (!uploaded.value && show.value.uploadStatus === 'uploading') {
        Modal.confirm({
            type: 'warning',
            title: '关闭将取消Excel数据导入，确定要关闭吗？',
            content: '离开后将会放弃本次的所有内容！',
            onOk: () => {
                show.value = init();
                uploadName.value = '';
                importVisible.value = false;
                if (!cancelFile) return;
                // 调用停止请求方法
                removePending({ url: props.url, method: 'post', data: cancelFile });
            }
        });
    } else {
        show.value = init();
        uploadName.value = '';
        importVisible.value = false;
    }
};

const radio = ref<'add' | 'update'>('add');

const uploaded = computed(() => percentage.value === 100);
</script>

<template>
    <div class="import">
        <el-button @click="importVisible = true">Excel导入</el-button>
        <input ref="fileInput" type="file" hidden accept=".xlsx, .xls" @change="handleSubmit" />
        <q-modal
            v-model:visible="importVisible"
            width="820px"
            title="导入"
            :ok-button-props="{
                disabled: uploaded && show.uploadStatus === 'uploading'
            }"
            :hide-cancel-btn="true"
            @ok="handleClose"
        >
            <!-- title 单选框 & 提示 -->
            <transition mode="out-in" name="slide-fade">
                <div v-if="show.radio" class="m-b-8">
                    <el-radio-group v-model="radio">
                        <el-radio label="add" value="add">导入新数据</el-radio>

                        <el-radio v-if="!hideUpdateBtn" label="update" value="update"
                            >更新现有数据</el-radio
                        >
                    </el-radio-group>
                </div>

                <import-tip v-else-if="show.tip" :text="tipContent" :type="tipType"></import-tip>
            </transition>
            <!-- 上传文件区域 -->
            <div
                class="upload-area"
                :class="{ 'area-active': show.dragover }"
                @drop.prevent="onDrop"
                @dragover.prevent="show.dragover = true"
                @dragleave.prevent="show.dragover = false"
            >
                <div
                    v-if="show.uploadStatus === 'uploading'"
                    class="upload-area__progress"
                    :class="{ progressAnimation: percentage === 100 }"
                >
                    <el-progress
                        :percentage="percentage"
                        :show-text="false"
                        :stroke-width="8"
                        style="margin-bottom: 8px"
                    />
                    <template v-if="uploaded">
                        <div v-show="percentage === 100">正在加载数据...</div>
                        <p class="upload-area__progress--err">请勿关闭弹框！</p>
                    </template>
                    <template v-else>
                        <p>{{ percentage }}%</p>
                        <p class="upload-area__progress--info">正在校验文件...</p>
                    </template>
                </div>
                <div v-else>
                    <q-icon
                        icon-class="excel"
                        size="60px"
                        :color="show.uploadStatus === 'uploaded' ? '#00BB2C' : '#D9D9D9'"
                    ></q-icon>
                    <template v-if="show.uploadStatus === 'uploaded'">
                        <p style="margin: 10px 0">
                            {{ uploadName }}
                        </p>
                        <span class="link" style="text-decoration: underline" @click="handleImport">
                            重新上传
                        </span>
                    </template>

                    <template v-else>
                        <div style="margin: 10px 0">
                            将文件过到此处，或 &nbsp;
                            <span
                                class="link"
                                style="text-decoration: underline"
                                @click="handleImport"
                            >
                                点击上传
                            </span>
                        </div>
                        <p class="type">支持xls，xlsx等类型的文件</p>
                    </template>
                </div>
            </div>
            <import-bottom :download-template="downloadTemplate" />
        </q-modal>
    </div>
</template>

<style scoped lang="scss">
@include b(link) {
    color: var(--q-color-primary);
    cursor: pointer;
}

@include b(upload-area) {
    box-sizing: border-box;
    width: 772px;
    height: 180px;
    padding: 32px 0 27px;
    font-size: 16px;
    text-align: center;
    background: var(--q-bg-color-import);
    border: 1px solid var(--q-bg-color-border);
    border-radius: 4px;

    @include e(progress) {
        width: 393px;
        margin: 45px auto;

        @include m(err) {
            margin-top: 8px;
            font-size: 12px;
            color: var(--q-color-danger);
        }

        @include m(info) {
            margin-top: 8px;
            color: var(--q-text-color-secondary);
        }
    }

    &:hover {
        border: 1px dashed var(--q-color-primary);
    }
}

@include b(el-progress) {
    :deep(.el-progress-bar__inner) {
        background: linear-gradient(270deg, #06f 0%, #9d6fff 100%);
    }
}

@include b(progressAnimation) {
    :deep(.el-progress-bar__inner) {
        &::after {
            position: absolute;
            inset: 0;
            content: '';
            background-image: linear-gradient(
                45deg,
                rgb(0 0 0 / 20%) 25%,
                transparent 25%,
                transparent 50%,
                rgb(0 0 0 / 20%) 50%,
                rgb(0 0 0 / 20%) 75%,
                transparent 75%,
                transparent
            );
            background-size: 1.25em 1.25em;
            animation: striped-flow 15s linear infinite;
        }
    }
}

// 单选框激活样式
.active {
    color: var(--q-color-primary);
    background: rgb(0 102 255 / 10%);
    border-color: var(--q-color-primary);

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        content: '';
        border: 14px solid var(--q-color-primary);
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
}

// 导入区域激活样式
.area-active {
    border: 2px dashed var(--q-color-primary);
}

@include b(type) {
    color: var(--q-bg-color-radio);
}

// tarnsition 组件动画
.slide-fade-enter-active {
    transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}

// 进度条动画
@keyframes striped-flow {
    0% {
        background-position: -100%;
    }

    100% {
        background-position: 100%;
    }
}
</style>
