<script setup lang="ts">
import { watchRoute } from '@/router/helper/handle';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import en from 'element-plus/dist/locale/en.mjs';

const isRouterAlive = ref(true);

/**
 * 重载页面，无感刷新
 */
const reload = () => {
    return new Promise(res => {
        isRouterAlive.value = false;
        nextTick(() => {
            isRouterAlive.value = true;
            res(true);
        });
    });
};

provide('reload', reload);

const name = useRoute().name as string;
const LocaleStore = localeStore();
const Locale = computed(() => (LocaleStore.local === 'zh-US' ? zhCn : en));

// 路由监听
watchRoute();
</script>

<template>
    <el-config-provider :locale="Locale">
        <div v-if="isRouterAlive" class="l-app__main">
            <router-view v-slot="{ Component }" :key="name">
                <component :is="Component" />
            </router-view>
        </div>
    </el-config-provider>
</template>

<style scoped lang="scss">
@include b(l-app) {
    @include e(main) {
        height: 100vh;
    }
}
</style>
