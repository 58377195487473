/*
 * @Description: 静态路由
 * @Author: James324
 * @Date: 2023-08-24 17:00:30
 * @LastEditors: James324
 * @LastEditTime: 2024-03-27 11:20:03
 */
const Layout = () => import('@/views/Layout/index.vue');
const Login = () => import('@/views/Login/index.vue');
const NotFound = () => import('@/views/NotFound/index.vue');

// 静态路由
export const constantRoute = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: '启山智软APS登录',
            hidden: true,
            hideLayout: true
        }
    },
    {
        path: '/',
        name: 'Home',
        component: Layout,
        redirect: '/home',
        meta: {
            title: '首页',
            hidden: true,
            hideCollapsed: true
        },
        children: [
            {
                path: '/home',
                name: 'HomeIndex',
                level: 2,
                component: () => import('@/views/Home/index.vue'),
                parent: 'Home',
                meta: {
                    title: '主页',
                    menu: '主页',
                    hidden: true
                }
            },
            {
                path: '/noAuth',
                name: 'NoAuth',
                parent: 'Home',
                level: 2,
                component: () => import('@/views/NoAuth/index.vue'),
                meta: {
                    title: '无权限',
                    menu: '无权限',
                    hidden: true
                }
            },
            {
                path: '/404',
                name: 'NotFound',
                component: NotFound,
                parent: 'Home',
                meta: {
                    title: '404',
                    hidden: true
                }
            }
        ]
    },
    {
        path: '/messages',
        name: 'Messages',
        component: Layout,
        redirect: '/messages',
        meta: {
            title: '消息',
            hidden: true,
            hideCollapsed: true
        },
        children: [
            {
                path: '',
                name: 'MessagesIndex',
                level: 2,
                component: () => import('@/views/Messages/index.vue'),
                parent: 'Messages',
                meta: {
                    title: '消息列表',
                    menu: '消息列表',
                    hidden: true
                }
            }
        ]
    },
    {
        path: '/:catchAll(.*)',
        component: NotFound,
        meta: {
            hidden: true
        }
    }
];

// 404
export const NotFoundRoute = [
    {
        path: '/:pathMatch(.*)',
        redirect: '/404',
        name: 'Any',
        meta: {
            hidden: true
        }
    }
];
