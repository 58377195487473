/*
 * @Description: 异步路由
 * @Author: James324
 * @Date: 2023-08-24 16:58:55
 * @LastEditors: James324
 * @LastEditTime: 2024-03-19 16:11:19
 * @Tip: 详情二级页面可以两种形式存在，1. 按钮形式， 2. 菜单形式
 * @Tip: 针对详情页如果菜单管理中页面是以按钮形式存在，则通过配置 auth 字段控制该路由权限， auth 值为菜单管理编辑项中的 URL 字段值
 * @Tip: 如果页面存在动态参数路由如 /process/:name? 的情况，需在菜单管理中路由地址需保持同样的地址格式配置才可权限生效
 */
const Layout = () => import('@/views/Layout/index.vue');

export const userAsyncRoutes = [
    {
        path: '/plan',
        name: 'Plan',
        redirect: '/plan',
        component: Layout,
        level: 1,
        meta: {
            title: '计划',
            menu: '计划',
            icon: 'xuqiujihua',
            hidden: false
        },
        children: [
            {
                path: '',
                name: 'Task',
                redirect: '/plan',
                level: 2,
                parent: 'Plan',
                children: [
                    {
                        path: '',
                        name: 'TaskList',
                        level: 3,
                        component: () => import('@/views/Plan/Task/index.vue'),
                        parent: 'Task',
                        meta: {
                            title: '任务视图列表',
                            menu: '任务视图列表',
                            hidden: false
                        }
                    }
                ],
                meta: {
                    title: '任务视图',
                    menu: '任务视图',
                    hidden: false
                }
            },
            {
                path: '/gantt',
                name: 'Gantt',
                redirect: '/gantt',
                level: 2,
                parent: 'Plan',
                children: [
                    {
                        path: '',
                        name: 'GanttList',
                        level: 3,
                        component: () => import('@/views/Plan/Gantt/index.vue'),
                        parent: 'Gantt',
                        meta: {
                            title: '设备甘特图列表',
                            menu: '设备甘特图列表',
                            hidden: false
                        }
                    }
                ],
                meta: {
                    title: '设备甘特图',
                    menu: '设备甘特图',
                    hidden: false
                }
            },
            {
                path: '/orderGantt',
                name: 'OrderGantt',
                redirect: '/orderGantt',
                level: 2,
                parent: 'Plan',
                children: [
                    {
                        path: '',
                        name: 'OrderGanttList',
                        level: 3,
                        component: () => import('@/views/Plan/OrderGantt/index.vue'),
                        parent: 'OrderGantt',
                        meta: {
                            title: '订单甘特图列表',
                            menu: '订单甘特图列表',
                            hidden: false
                        }
                    }
                ],
                meta: {
                    title: '订单甘特图',
                    menu: '订单甘特图',
                    hidden: false
                }
            },
            {
                path: '/scheduling',
                name: 'Scheduling',
                redirect: '/scheduling',
                level: 2,
                component: () => import('@/views/Plan/Scheduling/index.vue'),
                parent: 'Plan',
                children: [
                    {
                        path: '',
                        name: 'SchedulingList',
                        level: 3,
                        component: () => import('@/views/Plan/Scheduling/index.vue'),
                        parent: 'Scheduling',
                        meta: {
                            title: '排程明细列表',
                            menu: '排程明细列表',
                            hidden: false
                        }
                    }
                ],
                meta: {
                    title: '工序排程明细',
                    menu: '工序排程明细',
                    hidden: false
                }
            },
            {
                path: '/suggest',
                name: 'suggest',
                redirect: '/suggest',
                level: 2,
                parent: 'Plan',
                children: [
                    {
                        path: '',
                        name: 'suggestList',
                        level: 3,
                        component: () => import('@/views/Plan/Suggest/index.vue'),
                        parent: 'suggest',
                        meta: {
                            title: '建议计划列表',
                            menu: '建议计划列表',
                            hidden: false
                        }
                    }
                ],
                meta: {
                    title: '建议计划',
                    menu: '建议计划',
                    hidden: false
                }
            },
            {
                path: '/procure',
                name: 'Procure',
                redirect: '/procure',
                level: 2,
                parent: 'Plan',
                children: [
                    {
                        path: '',
                        name: 'ProcureList',
                        level: 3,
                        component: () => import('@/views/Plan/Procure/index.vue'),
                        parent: 'Procure',
                        meta: {
                            title: '采购计划列表',
                            menu: '采购计划列表',
                            hidden: false
                        }
                    }
                ],
                meta: {
                    title: '采购计划',
                    menu: '采购计划',
                    hidden: false
                }
            }
        ]
    },
    {
        path: '/Demand',
        name: 'Demand',
        redirect: '/Demand',
        component: Layout,
        level: 1,
        meta: {
            title: '需求',
            menu: '需求',
            icon: 'renwu',
            hidden: false
        },
        children: [
            {
                path: '',
                name: 'Order',
                redirect: '/Demand',
                level: 2,
                parent: 'Demand',
                children: [
                    {
                        path: '',
                        name: 'OrderList',
                        level: 3,
                        component: () => import('@/views/Demand/Order/index.vue'),
                        parent: 'Order',
                        meta: {
                            title: '需求订单列表',
                            menu: '需求订单列表',
                            hidden: false
                        }
                    },
                    {
                        path: '/order/add',
                        name: 'OrderAdd',
                        level: 3,
                        component: () => import('@/views/Demand/Order/view/Add/index.vue'),
                        parent: 'Order',
                        meta: {
                            title: '新增需求订单',
                            menu: '新增需求订单',
                            auth: 'orderInformation:create',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '需求订单',
                    menu: '需求订单',
                    hidden: false
                }
            },
            {
                path: '/arithmetic',
                name: 'Arithmetic',
                redirect: '/arithmetic',
                level: 2,
                parent: 'Demand',
                children: [
                    {
                        path: '',
                        name: 'ArithmeticList',
                        level: 3,
                        component: () => import('@/views/Demand/Arithmetic/index.vue'),
                        parent: 'Arithmetic',
                        meta: {
                            title: '排程运算列表',
                            menu: '排程运算列表',
                            hidden: false
                        }
                    },
                    {
                        path: '/arithmetic/scheduleDetail',
                        name: 'ScheduleDetail',
                        level: 3,
                        component: () =>
                            import('@/views/Demand/Arithmetic/Schedule-detail/index.vue'),
                        parent: 'Arithmetic',
                        meta: {
                            title: '排程明细',
                            auth: 'schedule:self:control',
                            hidden: true
                        }
                    },
                    {
                        path: '/arithmetic/processDetail',
                        name: 'ProcessDetail',
                        level: 3,
                        component: () =>
                            import('@/views/Demand/Arithmetic/Process-detail/index.vue'),
                        parent: 'Arithmetic',
                        meta: {
                            title: '工序明细',
                            auth: 'schedule:self:control',
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '排程运算',
                    menu: '排程运算',
                    hidden: false
                }
            }
        ]
    },
    {
        path: '/bulletin',
        name: 'Bulletin',
        redirect: '/bulletin',
        component: Layout,
        level: 1,
        meta: {
            title: '看板',
            menu: '看板',
            icon: 'shuju',
            hidden: false
        },
        children: [
            {
                path: '',
                name: 'jobBooking',
                redirect: '/bulletin',
                level: 2,
                parent: 'Bulletin',
                children: [
                    {
                        path: '',
                        name: 'jobBookingList',
                        level: 3,
                        component: () => import('@/views/Bulletin/JobBooking/index.vue'),
                        parent: 'jobBooking',
                        meta: {
                            title: '生产报工列表',
                            menu: '生产报工列表',
                            hidden: false
                        }
                    },
                    {
                        path: '/jobBooking/add',
                        name: 'jobBookingAdd',
                        level: 3,
                        component: () => import('@/views/Bulletin/JobBooking/view/Add/index.vue'),
                        parent: 'jobBooking',
                        meta: {
                            auth: 'production:newspaper:worker:create',
                            title: '新增生产报工',
                            menu: '新增生产报工',
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '生产报工',
                    menu: '生产报工',
                    hidden: false
                }
            },
            {
                path: '/Analysis',
                name: 'analysis',
                redirect: '/Analysis',
                level: 2,
                parent: 'Bulletin',
                children: [
                    {
                        path: '',
                        name: 'analysisList',
                        level: 3,
                        component: () => import('@/views/Bulletin/Analysis/index.vue'),
                        parent: 'analysis',
                        meta: {
                            title: '齐套分析列表',
                            menu: '齐套分析列表',
                            hidden: false
                        }
                    }
                ],
                meta: {
                    title: '齐套分析',
                    menu: '齐套分析',
                    hidden: false
                }
            },
            {
                path: '/deviceTask',
                name: 'DeviceTask',
                redirect: '/deviceTask',
                level: 2,
                parent: 'Bulletin',
                children: [
                    {
                        path: '',
                        name: 'DeviceTaskList',
                        level: 3,
                        component: () => import('@/views/Bulletin/DeviceTask/index.vue'),
                        parent: 'DeviceTask',
                        meta: {
                            title: '设备看板列表',
                            menu: '设备看板列表',
                            hidden: false
                        }
                    },
                    {
                        path: '/deviceTaskDetails',
                        name: 'DeviceTaskDetails',
                        level: 3,
                        component: () => import('@/views/Bulletin/DeviceTaskDetails/index.vue'),
                        parent: 'DeviceTask',
                        meta: {
                            auth: 'equipment:production:detail:all',
                            title: '设备任务详情',
                            menu: '设备任务详情',
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '设备任务看板',
                    menu: '设备任务看板',
                    hidden: false
                }
            },
            {
                path: '/shopProduction',
                name: 'ShopProduction',
                redirect: '/shopProduction',
                level: 2,
                parent: 'Bulletin',
                children: [
                    {
                        path: '',
                        name: 'ShopProductionList',
                        level: 3,
                        component: () => import('@/views/Bulletin/ShopProduction/index.vue'),
                        parent: 'ShopProduction',
                        meta: {
                            title: '车间看板列表',
                            menu: '车间看板列表',
                            hidden: false
                        }
                    },
                    {
                        path: '/shopProductionDetails',
                        name: 'ShopProductionDetails',
                        level: 3,
                        component: () => import('@/views/Bulletin/ShopProductionDetails/index.vue'),
                        parent: 'ShopProduction',
                        meta: {
                            auth: 'workshop:production:detail:multi',
                            title: '车间详情',
                            menu: '车间详情',
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '车间生产看板',
                    menu: '车间生产看板',
                    hidden: false
                }
            }
        ]
    },
    {
        path: '/modeling',
        name: 'Modeling',
        redirect: '/modeling',
        component: Layout,
        level: 1,
        meta: {
            title: '建模',
            menu: '建模',
            icon: 'moxingkuaisuchaifen',
            hidden: false
        },
        children: [
            {
                path: '/equipment',
                name: 'Equipment',
                redirect: '/equipment',
                level: 2,
                parent: 'Modeling',
                children: [
                    {
                        path: '',
                        name: 'EquipmentList',
                        level: 3,
                        component: () => import('@/views/Modeling/Equipment/index.vue'),
                        parent: 'Equipment',
                        meta: {
                            title: '设备列表',
                            menu: '设备管理 ',
                            hidden: false
                        }
                    },
                    {
                        path: '/equipment/add',
                        name: 'EquipmentAdd',
                        level: 3,
                        component: () => import('@/views/Modeling/Equipment/view/Add/index.vue'),
                        parent: 'Equipment',
                        meta: {
                            title: '新增设备',
                            menu: '新增设备 ',
                            auth: 'equipment:save',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '设备管理',
                    menu: '设备管理 ',
                    hidden: false
                }
            },
            {
                path: '/auxiliaryResources',
                name: 'AuxiliaryResources',
                redirect: '/auxiliaryResources',
                level: 2,
                parent: 'Modeling',
                children: [
                    {
                        path: '',
                        name: 'AuxiliaryResourcesList',
                        level: 3,
                        component: () => import('@/views/Modeling/AuxiliaryResources/index.vue'),
                        parent: 'AuxiliaryResources',
                        meta: {
                            title: '辅助资源列表',
                            menu: '辅助资源列表 ',
                            hidden: false
                        }
                    },
                    {
                        path: '/auxiliaryResources/add',
                        name: 'AuxiliaryResourcesAdd',
                        level: 3,
                        component: () =>
                            import('@/views/Modeling/AuxiliaryResources/view/Add/index.vue'),
                        parent: 'AuxiliaryResources',
                        meta: {
                            title: '新增辅助资源',
                            menu: '新增辅助资源 ',
                            auth: 'auxiliaryData:save',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '辅助资源',
                    menu: '辅助资源 ',
                    hidden: false
                }
            },
            {
                path: '/process',
                name: 'ProcessManagement',
                redirect: (to: any) => {
                    return {
                        name: 'ProcessManagementList',
                        params: to.params.name ? { name: to.params.name } : {}
                    };
                },
                level: 2,
                parent: 'Modeling',
                children: [
                    {
                        path: '/process/:name?',
                        name: 'ProcessManagementList',
                        level: 3,
                        component: () => import('@/views/Modeling/ProcessManagement/index.vue'),
                        parent: 'ProcessManagement',
                        meta: {
                            title: '工艺列表',
                            menu: '工艺列表 ',
                            hidden: false
                        }
                    },
                    {
                        path: '/process/add',
                        name: 'ProcessManagementAdd',
                        level: 3,
                        component: () =>
                            import(
                                '@/views/Modeling/ProcessManagement/view/ProcessList/view/Add/index.vue'
                            ),
                        parent: 'ProcessManagement',
                        meta: {
                            title: '新增工艺路线',
                            menu: '新增工艺路线',
                            auth: 'process:route:save',
                            confirm: true,
                            hidden: true
                        }
                    },
                    {
                        path: '/process/edit',
                        name: 'ProcessManagementEdit',
                        level: 3,
                        component: () =>
                            import(
                                '@/views/Modeling/ProcessManagement/view/ProcessList/view/Edit/index.vue'
                            ),
                        parent: 'ProcessManagement',
                        meta: {
                            title: '编辑工艺路线',
                            menu: '编辑工艺路线 ',
                            auth: 'process:route:update',
                            confirm: true,
                            hidden: true
                        }
                    },
                    {
                        path: '/process/procedureList/add',
                        name: 'ProcedureListAdd',
                        level: 3,
                        component: () =>
                            import(
                                '@/views/Modeling/ProcessManagement/view/ProcedureList/view/Add/index.vue'
                            ),
                        parent: 'ProcessManagement',
                        meta: {
                            title: '新增工序',
                            menu: '新增工序',
                            auth: 'working:procedure:save',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '工艺管理',
                    menu: '工艺管理 ',
                    hidden: false
                }
            },
            {
                path: '',
                name: 'BOM',
                redirect: '/modeling',
                parent: 'Modeling',
                level: 2,
                meta: {
                    title: 'BOM',
                    menu: 'BOM',
                    hidden: false
                },
                children: [
                    {
                        path: '',
                        name: 'BOMList',
                        level: 3,
                        component: () => import('@/views/Modeling/Bom/view/BomList/index.vue'),
                        parent: 'BOM',
                        meta: {
                            title: 'BOM列表',
                            menu: 'BOM列表 ',
                            hidden: false
                        }
                    },
                    {
                        path: '/modeling/add',
                        name: 'BOMAdd',
                        level: 3,
                        component: () => import('@/views/Modeling/Bom/view/Add/index.vue'),
                        parent: 'BOM',
                        meta: {
                            title: '新增BOM',
                            menu: '新增BOM',
                            auth: 'bom:save',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    },
                    {
                        path: '/modeling/edit',
                        name: 'BOMEdit',
                        level: 3,
                        component: () => import('@/views/Modeling/Bom/view/Edit/index.vue'),
                        parent: 'BOM',
                        meta: {
                            title: '编辑BOM',
                            menu: '编辑BOM',
                            auth: 'bom:update',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    },
                    {
                        path: '/BomDetail',
                        name: 'BomDetail',
                        level: 3,
                        component: () => import('@/views/Modeling/Bom/view/BomDetail/index.vue'),
                        parent: 'BOM',
                        meta: {
                            title: 'BOM明细',
                            menu: 'BOM明细 ',
                            auth: ['bom:select:bom:detail', 'bom:select:bom:detail?order'],
                            hidden: true
                        }
                    },
                    {
                        path: '/BomTree',
                        name: 'BOMTree',
                        level: 3,
                        component: () => import('@/views/Modeling/Bom/view/BomTree/index.vue'),
                        parent: 'BOM',
                        meta: {
                            title: 'BOM层级视图',
                            menu: 'BOM层级视图 ',
                            auth: 'bom:translate:view',
                            hidden: true
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/base',
        name: 'Base',
        redirect: '/base',
        component: Layout,
        level: 1,
        meta: {
            title: '基础',
            menu: '基础',
            icon: 'jichupeizhiguanli',
            hidden: false
        },
        children: [
            {
                path: '',
                name: 'CustomerInformation',
                redirect: '/base',
                parent: 'Base',
                level: 2,
                children: [
                    {
                        path: '',
                        name: 'CustomerInformationList',
                        level: 3,
                        component: () => import('@/views/Base/CustomerInformation/index.vue'),
                        parent: 'CustomerInformation',
                        meta: {
                            title: '客户列表',
                            menu: '客户列表',
                            hidden: false
                        }
                    },
                    {
                        path: '/customerInformation/add',
                        name: 'CustomerInformation',
                        level: 3,
                        component: () =>
                            import('@/views/Base/CustomerInformation/view/Add/index.vue'),
                        parent: 'CustomerInformation',
                        meta: {
                            title: '新增客户信息',
                            menu: '新增客户信息',
                            auth: 'customerInformation:save',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '客户信息',
                    menu: '客户信息',
                    hidden: false
                }
            },
            {
                path: '/workCalendar',
                name: 'workCalendar',
                level: 2,
                redirect: (to: any) => {
                    // 方法接收目标路由作为参数
                    // return 重定向的字符串路径/路径对象
                    return {
                        name: 'workCalendarList',
                        params: to.params.name ? { name: to.params.name } : {}
                    };
                },
                parent: 'Base',
                children: [
                    {
                        path: '/workCalendar/:name?',
                        name: 'workCalendarList',
                        level: 3,
                        component: () => import('@/views/Base/WorkCalendar/index.vue'),
                        parent: 'workCalendar',
                        meta: {
                            title: '日历列表',
                            menu: '日历列表',
                            hidden: false
                        }
                    },
                    {
                        path: '/workCalendar/workingHours/add',
                        name: 'WorkingHoursAdd',
                        level: 3,
                        component: () =>
                            import(
                                '@/views/Base/WorkCalendar/view/WorkingHours/view/Add/index.vue'
                            ),
                        parent: 'workCalendar',
                        meta: {
                            title: '新增工作时段',
                            menu: '新增工作时段',
                            auth: 'workingPeriod:save',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    },
                    {
                        path: '/workCalendar/shiftSystem/add',
                        name: 'ShiftSystemAdd',
                        level: 3,
                        component: () =>
                            import('@/views/Base/WorkCalendar/view/ShiftSystem/view/Add/index.vue'),
                        parent: 'workCalendar',
                        meta: {
                            title: '新增班制',
                            menu: '新增班制',
                            auth: 'classSystem:create',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '工作日历',
                    menu: '工作日历',
                    hidden: false
                }
            },
            {
                path: '/Supplier',
                name: 'supplier',
                redirect: '/Supplier',
                level: 2,
                parent: 'Base',
                children: [
                    {
                        path: '',
                        name: 'supplierList',
                        level: 3,
                        component: () => import('@/views/Base/Supplier/index.vue'),
                        parent: 'supplier',
                        meta: {
                            title: '供应商列表',
                            menu: '供应商列表',
                            hidden: false
                        }
                    },
                    {
                        path: '/supplier/add',
                        name: 'SupplierAdd',
                        level: 3,
                        component: () => import('@/views/Base/Supplier/view/Add/index.vue'),
                        parent: 'supplier',
                        meta: {
                            title: '新增供应商',
                            menu: '新增供应商',
                            auth: 'supplier:save',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '供应商',
                    menu: '供应商',
                    hidden: false
                }
            },
            {
                path: '/materialsManagement',
                name: 'MaterialsManagement',
                redirect: '/materialsManagement',
                level: 2,
                parent: 'Base',
                children: [
                    {
                        path: '',
                        name: 'MaterialsManagementList',
                        level: 3,
                        component: () => import('@/views/Base/MaterialsManagement/index.vue'),
                        parent: 'MaterialsManagement',
                        meta: {
                            title: '物料管理列表',
                            menu: '物料管理列表',
                            hidden: false
                        }
                    },
                    {
                        path: '/materialsManagement/add',
                        name: 'MaterialsManagementAdd',
                        level: 3,
                        component: () =>
                            import('@/views/Base/MaterialsManagement/view/Add/index.vue'),
                        parent: 'MaterialsManagement',
                        meta: {
                            title: '新增物料管理',
                            menu: '新增物料管理',
                            auth: 'material:save',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '物料管理',
                    menu: '物料管理',
                    hidden: false
                }
            },
            {
                path: '/Workshop',
                name: 'workshop',
                redirect: '/Workshop',
                level: 2,
                parent: 'Base',
                children: [
                    {
                        path: '',
                        name: 'workshopList',
                        level: 3,
                        component: () => import('@/views/Base/Workshop/index.vue'),
                        parent: 'workshop',
                        meta: {
                            title: '车间列表',
                            menu: '车间列表',
                            hidden: false
                        }
                    },
                    {
                        path: '/workshop/add',
                        name: 'WorkshopAdd',
                        level: 3,
                        component: () => import('@/views/Base/Workshop/view/Add/index.vue'),
                        parent: 'workshop',
                        meta: {
                            title: '新增车间',
                            menu: '新增车间',
                            auth: 'workshop:save',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '车间',
                    menu: '车间',
                    hidden: false
                }
            }
        ]
    },
    {
        path: '/system',
        name: 'System',
        redirect: '/system',
        component: Layout,
        level: 1,
        meta: {
            title: '系统',
            menu: '系统',
            icon: 'xitongguanli',
            hidden: false
        },
        children: [
            {
                path: '',
                name: 'MemberManagement',
                level: 2,
                redirect: '/system',
                parent: 'System',
                children: [
                    {
                        path: '',
                        name: 'MemberManagementList',
                        level: 3,
                        component: () => import('@/views/System/MemberManagement/index.vue'),
                        parent: 'MemberManagement',
                        meta: {
                            title: '成员列表',
                            menu: '成员管理',
                            hidden: false
                        }
                    },
                    {
                        path: '/system/add',
                        name: 'MemberManagementAdd',
                        level: 3,
                        component: () =>
                            import('@/views/System/MemberManagement/view/Add/index.vue'),
                        parent: 'MemberManagement',
                        meta: {
                            title: '新增成员',
                            menu: '新增成员',
                            auth: 'system:user:save',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '成员管理',
                    menu: '成员管理',
                    hidden: false
                }
            },
            {
                path: '/roleManagement',
                name: 'RoleManagement',
                redirect: '/roleManagement',
                level: 2,
                parent: 'System',
                children: [
                    {
                        path: '',
                        name: 'RoleManagementList',
                        level: 3,
                        component: () => import('@/views/System/RoleManagement/index.vue'),
                        parent: 'RoleManagement',
                        meta: {
                            title: '角色列表',
                            menu: '角色列表',
                            hidden: false
                        }
                    },
                    {
                        path: '/roleManagement/add',
                        name: 'RoleManagementAdd',
                        level: 3,
                        component: () => import('@/views/System/RoleManagement/view/Add/index.vue'),
                        parent: 'RoleManagement',
                        meta: {
                            title: '新增角色',
                            menu: '新增角色',
                            auth: 'system:role:save',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    },
                    {
                        path: '/roleManagement/edit',
                        name: 'RoleManagementEdit',
                        level: 3,
                        component: () =>
                            import('@/views/System/RoleManagement/view/Edit/index.vue'),
                        parent: 'RoleManagement',
                        meta: {
                            title: '编辑角色',
                            menu: '编辑角色',
                            auth: 'system:role:update',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '角色管理',
                    menu: '角色管理',
                    hidden: false
                }
            },
            {
                path: '/menuManagement',
                name: 'MenuManagement',
                redirect: '/menuManagement',
                level: 2,
                parent: 'System',
                children: [
                    {
                        path: '',
                        name: 'MenuManagementList',
                        level: 3,
                        component: () => import('@/views/System/MenuManagement/index.vue'),
                        parent: 'MenuManagement',
                        meta: {
                            title: '菜单列表',
                            menu: '菜单管理',
                            hidden: false
                        }
                    },
                    {
                        path: '/menuManagement/add',
                        name: 'MenuManagementAdd',
                        level: 3,
                        component: () => import('@/views/System/MenuManagement/view/Add/index.vue'),
                        parent: 'MenuManagement',
                        meta: {
                            title: '新增菜单',
                            menu: '新增菜单',
                            auth: 'system:menu:save',
                            confirm: true, // 开启关闭标签前二次确认
                            hidden: true
                        }
                    }
                ],
                meta: {
                    title: '菜单管理',
                    menu: '菜单管理',
                    hidden: false
                }
            },
            {
                path: '/operationlog',
                name: 'OperationLog',
                redirect: '/operationlog',
                level: 2,
                parent: 'System',
                children: [
                    {
                        path: '',
                        name: 'OperationLogList',
                        level: 3,
                        component: () => import('@/views/System/OperationLog/index.vue'),
                        parent: 'OperationLog',
                        meta: {
                            title: '操作日志列表',
                            menu: '操作日志列表',
                            hidden: false
                        }
                    }
                ],
                meta: {
                    title: '操作日志',
                    menu: '操作日志',
                    hidden: false
                }
            }
        ]
    }
];
