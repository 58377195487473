<!--
 * @Description: 国际化 i18n 组件
 * @Author: James324
 * @Date: 2023-08-30 09:49:51
 * @LastEditors: James324
 * @LastEditTime: 2023-09-12 18:04:29
-->
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { Message } from '@/instance';

const LocaleStore = localeStore();
const language = computed(() => LocaleStore.local);

// 切换语言的方法
const i18n = useI18n();
const handleSetLanguage = (lang: string) => {
    i18n.locale.value = lang;
    LocaleStore.local = lang;
    Message.success(
        lang === 'zh-US' ? `语言成功切换为中文` : `Language successfully switched to English`
    );
};
</script>

<template>
    <el-dropdown trigger="click" @command="handleSetLanguage">
        <el-button size="small">
            {{ language === 'zh-US' ? '中文' : 'Eglish' }}
        </el-button>
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item :disabled="language === 'zh-US'" command="zh-US">
                    中文
                </el-dropdown-item>
                <el-dropdown-item :disabled="language === 'en-US'" command="en-US">
                    English
                </el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>
