import QRequest from '@/service/index';
import type { RecordParams, RecordResponse } from './type';
import { generateNameToLevel } from '@/config/constant';

// 查看操作记录
export const doGetRecord = (params: RecordParams) => {
    return QRequest.get<RecordResponse>(`/bom/operate/record`, {
        params,
        headers: {
            loadingTarget: `#${generateNameToLevel('1')}`
        }
    });
};
