/*
 * @description: 主入口文件
 * @Author: James324
 * @Date: 2023-08-21 13:59:03
 * @LastEditors: James324
 * @LastEditTime: 2024-01-16 17:33:17
 */
import { createApp } from 'vue';
import router from './router';
import store from './store';
import directives from './directives';
import App from './App.vue';
import baseComponent from './components/index';
import i18n from './locale';

import '@/router/guard/index';
import '@/assets/style';
import 'element-plus/theme-chalk/dark/css-vars.css';

function initApp() {
    const app = createApp(App);

    app.use(router);
    app.use(store);
    app.use(directives);
    app.use(baseComponent);
    app.use(i18n);

    app.mount('#app');
}

setTimeout(() => {
    initApp();
}, 600);
