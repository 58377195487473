<!--
 * @description: 基础表格
 * @Author: Peng_Yong
 * @Date: 2023-08-18 17:48:30
 * @LastEditors: James324
 * @LastEditTime: 2024-03-20 10:41:31
-->
<template>
    <div class="l-table">
        <div id="m-table" :key="tableKey" v-scrollbar="true" class="m-table" v-bind="$attrs">
            <!-- 表格头部按钮-->
            <div
                :class="[
                    'm-table__header',
                    {
                        'sticky-modal__header': sticky,
                        'detail-page__header--sticky': detailPageSticky,
                        'fullscreen--sticky': expand
                    }
                ]"
                :style="{ paddingBottom: mb + 'px' }"
            >
                <div>
                    <template v-if="addTableRow">
                        <el-button
                            type="primary"
                            :disabled="tableConfig.disabeldAdd"
                            @click="handleAdd"
                            >添加行</el-button
                        >
                        <el-button
                            v-if="!props.closeDelRow"
                            :disabled="tableData.length <= 1 || !selectTableMultiData.length"
                            @click="handleBatchDelete"
                            >删除行</el-button
                        >
                    </template>
                    <slot name="tableHeadStart" />
                </div>
                <div class="m-tablehead--end">
                    <slot name="tableHeadEnd" />
                    <!-- 表格显示设置 -->
                    <q-tool
                        v-if="showSetting && !addTableRow"
                        v-model:visible="visible"
                        :list="configItems"
                        :disabled="toolDisabled"
                        tip-content="表格显示设置"
                        @reset="handleReset"
                        @change="handleChange"
                        @move-change="handleMoveChange"
                        @column-change="handleAffix"
                        @affix-item="handleAffixItem"
                    />
                    <!-- 全屏展示 -->
                    <q-fullscreen v-if="showFullScreen" ref="fullscreenRef" />
                </div>
            </div>
            <div
                v-show="errValidRef"
                :class="[
                    'm-table__errMsg',
                    {
                        'm-table__errMsg--sticky': sticky,
                        'detail-page__errMsg--sticky': detailPageSticky,
                        'fullscreen--sticky': expand
                    }
                ]"
            >
                {{ errValidRef?.content || '' }}
            </div>
            <div
                id="header-sticky-id"
                :class="[
                    'm-table__wrapper',
                    'header-sticky',
                    {
                        'sticky-modal': sticky,
                        'exclude-sticky': excludeSticky,
                        'detail-page__sticky': detailPageSticky,
                        'fullscreen--sticky': expand,
                        'no-flex': addTableRow
                    }
                ]"
            >
                <div class="m-table__main">
                    <el-table
                        :id="props.id || 'q-table'"
                        ref="tableRef"
                        highlight-current-row
                        :show-overflow-tooltip="{
                            popperClass: 'popover-scroll',
                            showArrow: false,
                            popperOptions: {
                                modifiers: [{ name: 'flip', options: { padding: 180 } }]
                            }
                        }"
                        :header-cell-class-name="`m-table__cell ${border ? '' : 'no-border'}`"
                        cell-class-name="m-table__body--cell"
                        row-key="id"
                        :border="border"
                        :height="height"
                        :max-height="modalMode ? maxHeight() : 'auto'"
                        :data="tableData"
                        :header-cell-style="{ height: '32px' }"
                        :row-style="{ height: bodyRowHeight }"
                        scrollbar-always-on
                        :indent="24"
                        :class="{
                            'm-default-table': !modalMode && !height,
                            'shadow-table': shadowTable
                        }"
                        v-bind="customAttr"
                        @selection-change="handleSelectionChange"
                        @current-change="handleCurrentChange"
                        @row-click="handleRowClick"
                        @sort-change="(obj: any) => $emit('sortChange', obj)"
                        @header-dragend="(...args: any) => emit('headerDragend', ...args)"
                    >
                        <el-table-column v-if="tableConfig.expand" :resizable="false" type="expand">
                            <template #default="props">
                                <slot name="expand" :props="props" :config="tableConfig" />
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="!tableConfig.unSelection"
                            type="selection"
                            reserve-selection
                            :fixed="false"
                            :resizable="false"
                            :width="props.selectionWidth || 40"
                            :selectable="handleSelectable"
                            class-name="no-resize"
                        />

                        <el-table-column
                            v-if="!tableConfig.unIndex && filterLabel('序号')"
                            label="序号"
                            type="index"
                            width="55px"
                            :fixed="tableConfig.indexFixed || affixIndexColumn"
                        />

                        <template
                            v-for="item in renderTableItems"
                            :key="item.columnProps?.label || item.validProps?.id || item.slotName"
                        >
                            <template v-if="item.tableSlot">
                                <slot :name="item.tableSlot" :item="item" />
                            </template>

                            <el-table-column
                                v-else-if="item.columnProps"
                                v-bind="transformProps(item.columnProps)"
                                :fixed="item.fixed || item.columnProps.fixed"
                                :class-name="item.columnProps.cellClassName"
                                class="m-table__column"
                            >
                                <template #header>
                                    <span
                                        v-if="showRequiredIcon(item as any)"
                                        class="u-table__required"
                                        >*</span
                                    >
                                    {{ item.columnProps.label }}

                                    <BaseTooltip
                                        :tip="showQuestionIcon(item)"
                                        :tip-msg="item.otherConfig?.tipMsg"
                                    />
                                    <div
                                        v-if="!showQuestionIcon(item) && item.sortItem"
                                        class="placeholder"
                                    ></div>
                                    <sort-item
                                        v-if="item.sortItem"
                                        :class="{
                                            ascending: ascending && currentSort === item.sortUrlKey,
                                            descending:
                                                descending && currentSort === item.sortUrlKey
                                        }"
                                        @sorting="e => handleSortChange(item, e)"
                                    />
                                </template>
                                <template #default="{ row, $index }">
                                    <q-form-item
                                        v-if="!item.noEdit && row.edit"
                                        :id="item.validProps?.id!"
                                    >
                                        <template v-if="item.slotName">
                                            <slot
                                                :name="item.slotName"
                                                :row="row"
                                                :index="$index"
                                                :item="item"
                                                :validFn="{
                                                    handleFocus,
                                                    handleInput,
                                                    setValidErrorVisible
                                                }"
                                            />
                                        </template>
                                        <template v-else-if="item.itemType === 'select'">
                                            <el-select
                                                v-model="row[item.modelKey!]"
                                                clearable
                                                :placeholder="`请选择`"
                                                v-bind="item.elSelectProps"
                                                @click.stop
                                                @focus="
                                                    (e: any) =>
                                                        handleFocus(e, row, {
                                                            config: item
                                                        })
                                                "
                                                @change="
                                                    setValidErrorVisible(false),
                                                        emit('selectChange', item, row)
                                                "
                                                @blur="setValidErrorVisible(false)"
                                            >
                                                <el-option
                                                    v-for="ite in item.elSelectProps?.options"
                                                    :key="ite.value"
                                                    :label="ite.label"
                                                    :value="ite.value"
                                                />
                                            </el-select>
                                        </template>
                                        <template v-else-if="item.itemType === 'date'">
                                            <el-date-picker
                                                v-model="row[item.modelKey!]"
                                                type="date"
                                                clearable
                                                placeholder="请选择"
                                                v-bind="item.elDatePickerProps"
                                                @click.stop
                                                @focus="
                                                    (e: any) =>
                                                        handleFocus(e, row, {
                                                            config: item,
                                                            index: $index
                                                        })
                                                "
                                                @change="setValidErrorVisible(false)"
                                                @blur="setValidErrorVisible(false)"
                                            />
                                        </template>
                                        <template v-else-if="item.itemType === 'dateTime'">
                                            <el-date-picker
                                                v-model="row[item.modelKey!]"
                                                type="datetime"
                                                clearable
                                                placeholder="请选择"
                                                v-bind="item.elDatetimePickerProps"
                                                @click.stop
                                                @focus="
                                                    (e: any) =>
                                                        handleFocus(e, row, {
                                                            config: item,
                                                            index: $index
                                                        })
                                                "
                                                @change="setValidErrorVisible(false)"
                                                @blur="setValidErrorVisible(false)"
                                            />
                                        </template>
                                        <template v-else-if="item.itemType === 'time'">
                                            <el-time-picker
                                                v-if="row.edit"
                                                v-model="row[item.modelKey!]"
                                                v-bind="item.elTimePickerProps"
                                                placeholder="请选择"
                                                @click.stop
                                                @focus="
                                                    (e: any) =>
                                                        handleFocus(e, row, {
                                                            config: item,
                                                            index: $index
                                                        })
                                                "
                                                @change="setValidErrorVisible(false)"
                                                @blur="setValidErrorVisible(false)"
                                            />
                                        </template>
                                        <template v-else-if="item.itemType === 'timeSelect'">
                                            <el-time-select
                                                v-model="row[item.modelKey!]"
                                                start="08:30"
                                                step="00:15"
                                                end="22:30"
                                                placeholder="请选择"
                                                @click.stop
                                                @focus="
                                                    (e: any) =>
                                                        handleFocus(e, row, {
                                                            config: item
                                                        })
                                                "
                                                @change="setValidErrorVisible(false)"
                                                @blur="setValidErrorVisible(false)"
                                            />
                                        </template>
                                        <template v-else-if="item.itemType === 'number'">
                                            <el-input-number
                                                v-model.trim="row[item.modelKey!]"
                                                placeholder="请输入"
                                                :controls="false"
                                                :precision="0"
                                                :min="1"
                                                v-bind="item.elInputNumberProps"
                                                @click.stop
                                                @focus="
                                                    (e: any) =>
                                                        handleFocus(e, row, {
                                                            config: item
                                                        })
                                                "
                                                @blur="setValidErrorVisible(false)"
                                                @keyup="
                                                    (e: any) => {
                                                        handleInput(e, row, {
                                                            config: item
                                                        });
                                                    }
                                                "
                                            />
                                        </template>
                                        <template v-else>
                                            <el-input
                                                v-if="row.edit"
                                                v-model.trim="row[item.modelKey!]"
                                                :type="item.elInputProps?.type || 'text'"
                                                :rows="1"
                                                autosize
                                                placeholder="请输入"
                                                v-bind="item.elInputProps"
                                                @click.stop
                                                @focus="
                                                    (e: any) =>
                                                        handleFocus(e, row, {
                                                            config: item
                                                        })
                                                "
                                                @blur="
                                                    (e: any) => {
                                                        row[item.modelKey!] = formatInput(
                                                            row,
                                                            item.modelKey!,
                                                            item.validFormatType,
                                                            {
                                                                showErrorPopover: (msg: string) => {
                                                                    showErrorPopover(e, msg);
                                                                },
                                                                hideErrorPopover
                                                            }
                                                        );
                                                        setValidErrorVisible(false);
                                                    }
                                                "
                                                @keyup="
                                                    (e: any) => {
                                                        row[item.modelKey!] = formatInput(
                                                            row,
                                                            item.modelKey!,
                                                            item.validFormatType,
                                                            {
                                                                showErrorPopover: (msg: string) => {
                                                                    showErrorPopover(e, msg);
                                                                },
                                                                hideErrorPopover
                                                            }
                                                        );
                                                        handleInput(e, row, {
                                                            config: item
                                                        });
                                                    }
                                                "
                                            ></el-input>
                                        </template>
                                    </q-form-item>
                                    <template v-else>
                                        <template v-if="item.slotName">
                                            <slot
                                                :name="item.slotName"
                                                :row="row"
                                                :index="$index"
                                                :item="item"
                                                :validFn="{
                                                    handleFocus,
                                                    handleInput,
                                                    setValidErrorVisible
                                                }"
                                            />
                                        </template>
                                        <span v-else>{{ row[item.modelKey!] ?? '--' }}</span>
                                    </template>
                                </template>
                            </el-table-column>
                        </template>
                        <slot name="tableColumnSlot" />

                        <el-table-column
                            v-if="filterAction || tableConfig.modalActionShow"
                            :show-overflow-tooltip="false"
                            label="操作"
                            :width="actionWidth || ActionWidth"
                            v-bind="tableConfig.actionProps"
                            :resizable="false"
                            fixed="right"
                            class-name="no-resize"
                        >
                            <template #default="{ row, $index }: { row: any; $index: number }">
                                <div :ref="el => setActionRef(el, $index)" class="action">
                                    <!-- TODO:优化 按钮判断逻辑，判断逻辑复杂时通过插槽写在外面 -->

                                    <template v-if="!row.unAction && row.edit">
                                        <q-loading
                                            v-show="row.btnSaveLoading"
                                            color="#06f"
                                            size="16px"
                                        />
                                        <el-link
                                            v-show="!row.btnSaveLoading"
                                            type="primary"
                                            @click.stop="
                                                confirmEdit(row, $index, handleValidFieldPass)
                                            "
                                            >保存</el-link
                                        >
                                        <el-link
                                            type="primary"
                                            class="m-l-24"
                                            @click.stop="cancelEdit(row)"
                                            >取消</el-link
                                        >
                                    </template>
                                    <template v-else>
                                        <slot
                                            v-if="!row.edit || row.unAction"
                                            name="preBtn"
                                            :row="row"
                                        />

                                        <el-link
                                            v-show="!closeEdit"
                                            v-if="
                                                has(tableConfig.editHas ?? []) ||
                                                tableConfig.modalActionShow
                                            "
                                            :class="{ 'm-l-24': hasPreName }"
                                            type="primary"
                                            :disabled="
                                                row.unAction ||
                                                (editDisabledFn && editDisabledFn(row))
                                            "
                                            @click.stop="handleEdit(row)"
                                        >
                                            编辑
                                        </el-link>
                                    </template>
                                    <slot
                                        v-if="!row.edit || row.unAction"
                                        name="action"
                                        :row="row"
                                    />
                                    <template v-if="!hideDel && !row.edit">
                                        <span
                                            v-if="
                                                has(tableConfig.delHas ?? []) ||
                                                tableConfig.modalActionShow
                                            "
                                            class="m-l-24"
                                        >
                                            <el-link
                                                v-if="
                                                    row.unAction ||
                                                    row.disabled ||
                                                    delDisabledFn?.(row)
                                                "
                                                :disabled="delDisabledFn?.(row)"
                                                type="primary"
                                                :z-index="pZIndex"
                                                @click.stop="handleDeleteRow(row)"
                                                >删除</el-link
                                            >
                                            <!-- !delDisabledFn?.(row) 不管有没有 delDisabledFn 只要是false 就显示这个 -->
                                            <q-popover
                                                v-else
                                                v-model:visible="row.visible"
                                                :z-index="pZIndex"
                                                @ok="() => deleteAction(row, $index)"
                                            >
                                                <span @click.stop>
                                                    <el-link type="danger" :z-index="pZIndex">
                                                        <q-loading
                                                            v-show="row.btnLoading"
                                                            color="#06f"
                                                            size="12px"
                                                        />
                                                        删除
                                                    </el-link>
                                                </span>
                                            </q-popover>
                                        </span>
                                    </template>
                                </div>
                            </template>
                        </el-table-column>

                        <template #empty>
                            <div>
                                <QEmpty
                                    ref="emptyRef"
                                    :add-table-row="addTableRow"
                                    :modal-mode="modalMode"
                                    :height="toPx(emptyHeight)"
                                />
                            </div>
                        </template>
                    </el-table>
                    <slot name="custom" />
                </div>

                <q-valid-popover
                    :popover-visible="popoverVisible"
                    :popover-err-msg="popoverErrMsg"
                />
            </div>
            <div class="m-table__scrollbar"></div>
            <!-- 指导性提示 -->
            <q-snackbar
                v-model="snackbarVisible"
                :text="snackbarText"
                :flip="snackOverflow"
                :show-arrow="true"
                :show-mask="true"
                :auto-close="false"
                :style="{ left: moveOffset.x, top: moveOffset.y }"
            />
        </div>
        <!-- 分页 -->
        <el-row v-if="showPage && !addTableRow && page" justify="end" class="m-table__pagination">
            <el-pagination
                :current-page="page.current"
                :page-size="page.size"
                :total="total"
                background
                layout="total, slot, sizes, prev, pager, next"
                :page-sizes="[10, 20, 50, 100]"
                @size-change="sizeChange"
                @current-change="currentChange"
            >
                <template #default>
                    <q-icon
                        icon-class="gengxinqi"
                        size="16px"
                        :class="['u-pagination-icon', { 'u-btn__loading': loading }]"
                        @click="
                            () => {
                                isRefresh = true;
                                getData?.();
                            }
                        "
                    ></q-icon>
                </template>
            </el-pagination>
        </el-row>
    </div>
</template>

<script setup lang="ts">
import sortItem from './components/sortItem/index.vue';
import { cloneDeep } from 'lodash-es';
import useSticky from '@/hooks/useSticky';
import useTableData from './hooks/useTableData';
import useTableHeight from './hooks/useTableHeight';
import useTool from './hooks/useTool';
import useHas from './hooks/useHas';
import useSort from './hooks/useSort';
import { useMouse } from './hooks/useMouse';
import { useInit } from './hooks/useInit';
import { useTableAction } from './hooks/useTableAction';
import { useValidTable } from './hooks/useValid';
import { formatInput, toPx, has } from '@/utils';
import type { BaseTableProps } from './type';

const visible = ref(false);
const props = withDefaults(defineProps<BaseTableProps>(), {
    editableKeys: () => [],
    mode: 'bottom',
    border: true, // 开启表格拖拽
    lineEdit: false, // 行内编辑
    addTableRow: false, // 表格数据新增
    modalMode: false, // modal 弹框模式
    showSetting: true,
    showPage: true,
    showFullScreen: false, // 显示全屏切换
    autoEditMode: false,
    closeEdit: false,
    hideDel: false,
    cellEdit: false, // 单元编辑
    customTableData: false,
    initOnceSticky: false, // 初始化一次吸顶效果，只在元素挂载 mount 阶段执行一次。
    refreshCacheData: true, // 刷新缓存组件表格的数据
    maxAddNum: 50,
    closeDelRow: false, // 关闭删除行按钮
    tableKey: new Date().getTime(),
    validPopoverPosition: 'left',
    bodyRowHeight: '44px',
    maxHeight: () => 300
});

const pZIndex = computed(() => (props.pZIndex || props.modalMode ? 3999 : 12));

const emit = defineEmits([
    'selectionChange',
    'change',
    'del',
    'add',
    'edit',
    'row-click',
    'load',
    'selectChange',
    'sortChange',
    'headerDragend'
]);

const slots = useSlots();
const tableItems = ref(cloneDeep(props.tableConfig!.items));
const renderTableItems = computed(() => tableItems.value.filter(item => !item.hide));
const toolDisabled = computed(() => tableItems.value.some(item => item.edit));
const sticky = computed(() => props.modalMode && (props.addTableRow || !!slots.tableHeadStart)); // 可粘性定位
const excludeSticky = computed(
    () => props.modalMode && !props.addTableRow && !slots.tableHeadStart
); // 不可粘性定位

/**
 * 设置是否默认初始化添加一次表格新单元格选项
 */
const setInit = (val: boolean) => {
    isInit.value = val;
};

const { detailPageSticky } = useSticky(props.openSticky, props.initOnceSticky);
const hasPreName = computed(() => slots.preBtn);

const {
    currentChange,
    sizeChange,
    getData,
    tableData,
    total,
    page,
    orderSort,
    loading,
    isRefresh,
    setTableData,
    resetTableData
} = useTableData({ props, emit, setInit });

const emptyHeight = computed(() => {
    return props.modalMode ? minHeight.value : +minHeight.value - 44;
});

/**
 * @: 设置操作ref
 */
const setActionRef = (el: any, $index: number) => {
    if (el) {
        actionRef.value[$index] = el;
    }
};

/**
 * 初始化表格一系列操作
 */
const { mb, customAttr, transformProps, shadowTable, showRequiredIcon, showQuestionIcon, editRef } =
    useInit(props, tableItems.value);

const { tableRef, snackbarVisible, snackOverflow, snackbarText, moveOffset } = useMouse(props);

const {
    filterLabel,
    configItems,
    affixIndexColumn,
    handleReset,
    handleChange,
    handleMoveChange,
    handleAffix,
    handleAffixItem
} = useTool(props, tableItems, tableRef);

// 根据权限计算出操作宽度
const { filterAction, ActionWidth } = useHas(props.tableConfig, filterLabel);

// 表格排序
const { handleSortChange, descending, ascending, currentSort } = useSort(orderSort, getData, emit);

const initData = () => {
    getData && getData();
};

const { minHeight, actionRef } = useTableHeight(
    {
        modalMode: props.modalMode,
        addTableRow: props.addTableRow,
        maxHeight: props.maxHeight,
        id: props.id || 'q-table',
        filterAction
    },
    tableRef
);

onMounted(() => {
    initData();
    initWatchTableData();
});

onActivated(() => {
    // 如果开启了缓存表格数据则刷新表格数据
    if (props.refreshCacheData) {
        initData();
    }
});

/**
 * 字段校验是否通过
 */
const { errValidRef, handleValidFieldPass } = useValidTable(props, tableData, {
    tableRef,
    tableItems: tableItems.value
});

/**
 * 操作表格逻辑
 */
const fullscreenRef = ref();
const expand = computed(() => fullscreenRef.value && fullscreenRef.value.expand);
const {
    initWatchTableData,
    confirmEdit,
    cancelEdit,
    deleteAction,
    add,
    handleEdit,
    handleSelectionChange,
    handleCurrentChange,
    handleDeleteRow,
    handleBatchDelete,
    handleRowClick,
    selectTableMultiData,
    selectTableRowData,
    handleSelectable,
    toggleRowSelection,
    toggleRowExpansion,
    tableToggleRowExpansion,
    setAsyncColumnWidth,
    setCurrentRow,
    getSaveData,
    isInit,
    getCellAddData,
    clearSelection,
    initAddRow
} = useTableAction(tableRef, {
    props,
    editRef,
    tableData,
    emit,
    tableItems: tableItems.value,
    expand,
    setInit,
    errValidRef
});

/**
 * 输入获取焦点，做出相对于校验
 */
const {
    popoverVisible,
    popoverErrMsg,
    handleInput,
    handleFocus,
    setValidErrorVisible,
    showErrorPopover,
    hideErrorPopover
} = useValid({
    position: props.validPopoverPosition
});

const handleAdd = useDebounceFn(() => {
    add();
}, 20);

defineExpose({
    getData,
    add,
    getSaveData,
    handleValidFieldPass,
    setValidErrorVisible,
    editRef,
    errValidRef,
    tableData,
    setTableData,
    resetTableData,
    toggleRowSelection,
    toggleRowExpansion,
    tableToggleRowExpansion,
    setAsyncColumnWidth,
    setCurrentRow,
    selectTableRowData,
    setInit,
    getCellAddData,
    page,
    clearSelection,
    initAddRow,
    elTable: tableRef,
    getDataFn: props.getDataFn,
    tableItems
});
</script>

<style scoped lang="scss">
@include b(l-table) {
    display: flex;
    flex: 1;
    flex-direction: column;
}

@include b(m-table) {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    transition: all 0.3s;

    @include e(pagination) {
        padding-top: 14px;
    }

    @include e(header) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @include e(main) {
        position: relative;
        height: 100%;

        @include m(mask) {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 56px;
            pointer-events: none;
            background: var(--q-bg-color-table-shadow);
        }
    }

    @include e(errMsg) {
        margin-bottom: 4px;
        font-size: 12px;
        color: #f00;
    }

    @include e(wrapper) {
        &:not(.no-flex) {
            flex: 1;
        }
    }
}

@include b(m-default-table) {
    :deep(.el-scrollbar__wrap) {
        overflow: hidden;
        overflow-x: auto;
    }
}

@include b(m-table) {
    @include e(scrollbar) {
        position: sticky;
        bottom: -35px;
        z-index: 12;
    }

    @include e(column) {
        position: relative;
    }
}

@include b(u-table) {
    @include e(required) {
        margin-right: 1px;
        color: #f00;
    }
}

@include b(u-pagination-icon) {
    margin-right: 0;
    margin-left: 12px;
    cursor: pointer;
}

@include b(u-table) {
    @include e(icon) {
        display: none;
        margin: 0 5px;
        cursor: pointer;
    }
}

@include b(action) {
    white-space: nowrap;
}

@include b(placeholder) {
    width: 6px;
    height: 6px;
}

@include b(m-tablehead) {
    @include m(end) {
        display: flex;
        align-items: center;
        justify-content: end;
    }
}

:deep() {
    @import '@/assets/style/common/reset-border.scss';

    .m-table__cell {
        &:not(.no-resize, .no-border) {
            &::after {
                position: absolute;
                top: 0;
                right: 0;
                display: none;
                width: 1px;
                height: 100%;
                content: '';
                background: linear-gradient(180deg, rgb(32 45 64 / 0%), rgb(32 45 64 / 12%));
            }
        }

        &:hover {
            &:not(.no-resize, .no-border) {
                &::after {
                    display: block;
                }
            }
        }

        .cell {
            display: flex;
        }
    }

    /* 解决 el-table show-tooltip-overflow 编辑模式缩放未溢出展示问题 */
    .q-form-item__content {
        max-width: 99.9%;
    }

    .sticky-modal__header {
        position: sticky;
        top: -12px;
        z-index: 15;
        background: var(--q-bg-color);

        &::after {
            position: absolute;
            top: -16px;
            display: block;
            width: 100%;
            height: 16px;
            content: '';
            background: var(--q-bg-color);
        }
    }

    .detail-page__header--sticky {
        position: sticky;
        top: 64px;
        z-index: 15;
        background: var(--q-bg-color);

        &::after {
            position: absolute;
            top: -16px;
            display: block;
            width: 100%;
            height: 16px;
            content: '';
            background: var(--q-bg-color);
        }

        &.fullscreen--sticky {
            top: 0;
        }
    }

    .m-table__errMsg--sticky {
        position: sticky;
        top: 22px;
        z-index: 15;
    }

    .detail-page__errMsg--sticky {
        position: sticky;
        top: 98px;
        z-index: 15;

        &.fullscreen--sticky {
            top: 35px;
        }
    }

    .sticky-modal {
        .el-table__header-wrapper {
            top: 36px;
        }
    }

    .detail-page__sticky {
        .el-table__header-wrapper {
            top: 112px;
        }

        &.fullscreen--sticky {
            .el-table__header-wrapper {
                top: 48px;
            }
        }
    }

    .exclude-sticky {
        .el-table__header-wrapper {
            top: -24px !important;
        }
    }

    .opacityHeader {
        display: none;
    }

    .m-table {
        .submit.is-sticky {
            &::before,
            &::after {
                display: none;
            }
        }
    }
}
</style>
