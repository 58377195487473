/*
 * @Description: 侧边菜单
 * @Author: James324
 * @Date: 2023-08-27 12:59:01
 * @LastEditors: James324
 * @LastEditTime: 2023-12-06 16:47:55
 */
import { STORAGE_PREFIX, SIDE_MENU_STATE } from '@/config';
import { type Route } from 'vue-router';

export default defineStore(
    'sideMenuStore',
    () => {
        const subCollapsed = ref(false);
        const subMenu = ref({} as Route);

        /**
         * 设置菜单展开收缩
         */
        const setSubCollapsed = (val: boolean) => (subCollapsed.value = val);

        return {
            subCollapsed,
            subMenu,
            setSubCollapsed
        };
    },
    {
        persist: {
            key: `${STORAGE_PREFIX}${SIDE_MENU_STATE}`
        }
    }
);
