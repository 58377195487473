/*
 * @Description: 鼠标滚轮事件
 * @Author: James324
 * @Date: 2023-09-06 15:05:45
 * @LastEditors: James324
 * @LastEditTime: 2023-11-28 14:24:12
 */
import timeLine from '../components/time-line.vue';

export function useMouse(
    timeLineRef: Ref<InstanceType<typeof timeLine> | undefined>,
    scroller: Ref<any>,
    scrollLeft: Ref<number>,
    totalWidth: Ref<number>,
    wrapperW: Ref<number>
) {
    let headerWrapper: HTMLElement;
    let cleardown: any;
    let clearup: any;
    let clearmove: any;
    let clearleave: any;
    onMounted(() => {
        setTimeout(() => {
            // 拿到可以滚动的元素
            headerWrapper = timeLineRef.value?.$el;

            /**
             * 监听鼠标事件
             */
            cleardown = useEventListener(headerWrapper, 'mousedown', handleMouseDown);
            clearup = useEventListener(headerWrapper, 'mouseup', handleMouseUp);
            clearleave = useEventListener(headerWrapper, 'mouseleave', handleMouseUp);
            clearmove = useEventListener(headerWrapper, 'mousemove', handleMove);
        }, 500);
    });

    onUnmounted(() => {
        cleardown?.();
        clearup?.();
        clearmove?.();
        clearleave?.();
    });

    /**
     * TODO: 鼠标拖拽事件，待补充
     */
    const mouseFlag = ref(false);
    const mouseOffset = ref(0);
    // 按下鼠标记录鼠标位置
    const handleMouseDown = (e: MouseEvent) => {
        mouseOffset.value = e.clientX;
        mouseFlag.value = true;
    };

    const handleMouseUp = () => {
        mouseFlag.value = false;
    };

    const handleMove = (e: MouseEvent) => {
        const totalW = totalWidth.value - wrapperW.value;
        if (mouseFlag.value) {
            // 设置水平方向的元素的位置
            const mouse = mouseOffset.value - (mouseOffset.value = e.clientX);
            const left = scroller.value.x - mouse < -totalW ? -totalW : scroller.value.x - mouse;
            if (-left < totalW && -left > 0) {
                scroller.value!.scrollTo(left, scroller.value.y, 0);
                headerWrapper.style.transform = `translateX(${scroller.value.x}px)`;
                scrollLeft.value = -scroller.value.x;
            }
        }
    };

    return {
        handleMouseDown,
        handleMouseUp,
        handleMove
    };
}
