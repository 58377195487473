import { Result } from '@/service/request/type';

/**
 * @description: 下载 Excel
 * @param {Result} data
 * @return {*}
 */
export const excelDownLoad = (data: Result) => {
    // 处理错误
    if (!data || data.type === 'application/json') return;

    // 把 data 转成 Blob
    const blob = new Blob([data as unknown as Blob], {
        type: data.type
    });
    // 非 IE 下载
    const link = document.createElement('a');
    if (data.name?.[0] === '"' && data.name?.[data.name.length - 1] === '"') {
        link.download = JSON.parse(data.name);
    } else {
        link.download = data.name || '';
    }
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
};
