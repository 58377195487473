export const list = [10, 15, 20, 30, 60, 120, 180, 240, 360, 720, 1440, 2880, 4320];
import { generateId } from '@/utils';
import type { Records } from '@/api/plan/gantt/model/type';
// 时间刻度下拉框数据
export const scaleList = list.map(value => {
    let label;
    if (value < 60) {
        label = value + '分钟';
    } else if (value >= 60 && value < 1440) {
        label = value / 60 + '小时';
    } else {
        label = value / 1440 + '天';
    }
    return {
        value,
        label
    };
});
function flattenNode(node: Records, newArr: Records[]) {
    if (node.node) {
        newArr.push({
            ...node.node
        });
    }
    // 处理 node.node.children 属性
    if (node.node?.children && node.node?.children.length) {
        node.node?.children.forEach(child => {
            newArr.push({
                ...child
            });
            flattenNode(child, newArr); // 递归处理每个子节点
        });
    }
    // 处理 children 属性
    if (node?.children && node?.children.length) {
        node.children.forEach(child => {
            newArr.push({
                ...child
            });
            flattenNode(child, newArr); // 递归处理每个子节点
        });
    }
}
export const flattenArrays = (arr: Records[]) => {
    const newArr: Records[] = [];
    arr.forEach(function (obj: Records) {
        newArr.push({
            ...obj
        });
        flattenNode(obj, newArr);
    });
    return newArr;
};

export const addUniqueIds = (objArray: Records[] = []) => {
    objArray.forEach((obj: Records) => {
        obj.id = generateId(); // 为当前对象添加唯一的 ID
        if (obj.node) {
            obj.node.id = generateId();
        }
        if (obj.node?.children && obj.node?.children.length) {
            obj.node.children.forEach(child => {
                child.id = generateId();
                addUniqueIds(child?.children ?? []);
            });
        }
        if (obj?.children && obj?.children.length) {
            obj.children.forEach(child => {
                child.id = generateId();
                addUniqueIds(child.children ?? []);
            });
        }
    });
    return objArray;
};
