/*
 * @Description: 状态管理及数据持久化
 * @Author: James324
 * @Date: 2023-08-18 10:58:10
 * @LastEditors: James324
 * @LastEditTime: 2024-03-15 13:35:51
 */
import piniaPersist from 'pinia-plugin-persistedstate';
import { storeReset } from './helper/storeReset';
import { STORAGE_PREFIX, GLOBAL_STATE } from '@/config';
import { login as Login, logout as Logout } from '@/api/login';
import { getUserInfo as GetUserInfo } from '@/api/user';
import { cloneDeep } from 'lodash-es';
import { getToken, removeToken, toLogin, flattenArray, findNames } from '@/utils';
import { constantRoute, userAsyncRoutes } from '@/router/routes';
import { RouteLocationNormalizedLoaded, Route, RouteRecordRaw } from 'vue-router';
import type { LoginRequest, UserInfoResponse } from '@/api/login/model/type';
import { type UserInfo } from '@/api/user/model/type';

export const GlobalStore = defineStore(
    'GlobalState',
    () => {
        /* 全屏 */
        const fullscreen = ref(false);
        /* 登录模块 START */
        const token = ref('');
        const tokenInfo = ref<UserInfoResponse>();
        const setToken = (data: string) => (token.value = data);
        const setTokenInfo = (data: UserInfoResponse) => (tokenInfo.value = data);
        const login = async (payload: LoginRequest) => {
            try {
                const res = await Login(payload);
                if (res.data) {
                    setToken(res.data.value);
                    setTokenInfo(res.data);
                }
                return res;
            } catch (error) {
                return Promise.reject(error);
            }
        };
        /* 登录模块 END */

        /* 用户信息模块 START */
        const userInfo = ref<Partial<UserInfo>>({});
        const roles = computed(() => userInfo.value.roles);
        const buttons = computed(() => userInfo.value.buttons);
        const isSuperAdmin = computed(() => userInfo.value.userRoles?.includes('SUPER_ADMIN')); // 是否为超级管理员
        const setUserInfo = (data: UserInfo) => (userInfo.value = data);

        /**
         * 获取用户信息
         */
        const getUserInfo = async () => {
            const SkeletonStore = skeletonStore();
            try {
                SkeletonStore.loading = true;
                const res = await GetUserInfo();

                if (res?.data) {
                    setUserInfo(res.data as UserInfo);
                    return res.data;
                }

                // 未获取到接口信息 清除 token, 退出登录
                Logout(getToken());
                removeToken();
                toLogin();
            } catch (error) {
                return error; // 用于全局路由守卫时网络出错动态路由注册
            } finally {
                SkeletonStore.loading = false;
            }
        };
        /* 用户信息模块 END */

        /* 路由模块 START */
        const allRoutes = ref<Route[]>([]);
        const asyncMenus = ref<string[]>([]);
        const allMenuNames = ref<string[]>([]);
        const activeMenu = ref<RouteLocationNormalizedLoaded>();
        const setActiveMenu = (route: RouteLocationNormalizedLoaded) => {
            activeMenu.value = route;
        };
        /**
         * 设置所有路由
         */
        const setAllRoutes = (constantRoutes: Route[], asyncRoutes: Route[]) => {
            const copyConstantRoutes = cloneDeep(constantRoutes);
            const oneLevelRoutes = asyncRoutes.filter(
                asyncRoute => asyncRoute.level === 1 || asyncRoute.name === 'Any'
            );

            // 子路由合并
            copyConstantRoutes.forEach((route: Route) => {
                asyncRoutes.forEach(asyncRoute => {
                    if (asyncRoute.parent && route.name === asyncRoute.parent) {
                        if (route.children) route.children.push(asyncRoute as RouteRecordRaw);
                    }
                });
            });

            allRoutes.value = [...copyConstantRoutes, ...oneLevelRoutes];

            setAllMenuNames(); // 处理所有菜单 name 集合
        };

        /**
         * 设置异步动态菜单 path 集合
         */
        const setAsyncMenus = (menus: string[]) => {
            if (isSuperAdmin.value) {
                const asyncRoute = flattenArray(userAsyncRoutes);
                menus = asyncRoute.filter(route => route.path).map(route => route.path);
            }
            asyncMenus.value = menus;
        };

        /**
         * 设置异步路由
         */
        const asyncRoutes = ref<Route>([]);
        const setAsyncRoutes = (routes: Route[]) => {
            asyncRoutes.value = routes;
        };

        /**
         * 设置路由 name 集合
         */
        const setAllMenuNames = () => {
            allMenuNames.value = findNames([...constantRoute, ...userAsyncRoutes]);
        };

        /* 路由模块 END */

        return {
            fullscreen,
            token,
            userInfo,
            allRoutes,
            asyncMenus,
            asyncRoutes,
            allMenuNames,
            roles,
            buttons,
            isSuperAdmin,
            setAllRoutes,
            login,
            getUserInfo,
            activeMenu,
            setActiveMenu,
            tokenInfo,
            setToken,
            setTokenInfo,
            setUserInfo,
            setAsyncMenus,
            setAsyncRoutes,
            setAllMenuNames
        };
    },
    {
        persist: {
            key: `${STORAGE_PREFIX}${GLOBAL_STATE}`
        }
    }
);

const pinia = createPinia();
pinia.use(storeReset);
pinia.use(piniaPersist); // 数据持久化

export default pinia;
