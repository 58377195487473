<!--
 * @Description: 
 * @Author: shilinzhou
 * @Date: 2024-02-02 10:18:45
 * @LastEditors: James324
 * @LastEditTime: 2024-03-15 15:16:28
-->
<template>
    <div>
        <el-button @click="open">Excel导出</el-button>
        <q-modal v-model:visible="modelVisible" :confirm-loading="loading" @ok="handleExportExcel">
            <el-radio-group v-model="radio" class="ml-4">
                <el-radio
                    label="select"
                    value="select"
                    size="large"
                    :disabled="selectIds.length === 0"
                >
                    导出选中数据（{{ selectIds.length }}条 ）
                </el-radio>
                <el-radio label="all" value="all" size="large">导出全部数据</el-radio>
            </el-radio-group>
        </q-modal>
    </div>
</template>

<script setup lang="ts">
import { Result } from '@/service/request/type';
import { excelDownLoad } from '@/utils/excelDownLoad';

const props = defineProps<{
    selectIds: (number | string)[];
    doExportExcel: (params: any) => Promise<Result<any>>;
    searchRef?: SearchRef;
    tableRef?: TableRef;
    paramsCb?: (key: any) => anyObj; // 导出全部的参数处理
    page?: { size: number; current: number };
    appendParams?: (key?: any) => anyObj; //导出全部和导出部分都能添加上的参数，只需要更新的参数传递回去
}>();

const modelVisible = ref(false);
const radio = ref('all');
const loading = ref(false);

const open = () => {
    if (props.selectIds.length) {
        radio.value = 'select';
    } else {
        radio.value = 'all';
    }
    modelVisible.value = true;
};

const excelParams = ref<any>({});
// 导出操作
const handleExcelParams = () => {
    const { searchRef, paramsCb } = props;
    if (searchRef) {
        // 如果需要对数据进行特殊处理传递函数进行处理
        if (paramsCb) {
            excelParams.value = paramsCb(searchRef.formData);
            return;
        }
        excelParams.value = searchRef.getForm?.();
    }
};

// 确定导出
const handleExportExcel = async () => {
    loading.value = true;
    handleExcelParams();
    const { selectIds, tableRef, appendParams } = props;
    let page: { current: number; size: number } | undefined = { current: 1, size: 100000 };
    let ids: (number | string)[] = [];
    const append = appendParams ? appendParams(excelParams.value) : {};
    let params = {
        ...excelParams.value,
        ids,
        ...page,
        ...append
    };
    if (radio.value === 'select') {
        params = {
            ids: selectIds,
            ...(tableRef?.page || props.page),
            size: selectIds.length,
            ...append
        };
    }
    try {
        const data = await props.doExportExcel(params);
        excelDownLoad(data);
        modelVisible.value = false;
    } catch (error) {
        console.log(error);
    }
    loading.value = false;
};
</script>

<style scoped></style>
