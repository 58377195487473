import { cloneDeep } from 'lodash-es';
import type { PiniaPluginContext, StoreState } from 'pinia';

export const storeReset = ({ store }: PiniaPluginContext) => {
    const initialState = cloneDeep(store.$state);
    store.$reset = () => {
        store.$patch(($state: StoreState<any>) => {
            Object.assign($state, cloneDeep(initialState));
        });
    };
};
