/*
 * @Description:
 * @Author: shilinzhou
 * @Date: 2024-01-24 19:06:55
 * @LastEditors: shilinzhou
 * @LastEditTime: 2024-03-07 18:11:05
 */
import { handleDelKey } from '@/utils';

import type { Result } from '@/service/request/type';
import { flattenArrays, addUniqueIds } from '@/components/q-gantt/data';
import type { Records } from '@/api/plan/gantt/model/type';
export default (getDataFn: (params: any) => Promise<Result>) => {
    const searchParams = ref<any>({});
    const isRefresh = ref(false);
    const tableData = ref<Records[]>([]); // 表格数据
    const page = ref({ current: 1, size: 20 }); // 页码
    const total = ref(0); // 总条数
    const time = ref<Date[]>([]); // 时间
    const startDate = ref('');
    const loading = ref(false);

    const sizeChange = (val: number) => {
        page.value.size = val;
        getData(searchParams.value);
    };

    const currentChange = (val: number) => {
        page.value.current = val;
        getData(searchParams.value);
    };

    const handleRefresh = () => {
        isRefresh.value = true;
        getData(searchParams.value);
    };
    // 获取数据
    const getData = async (params: any = {}) => {
        if (params) {
            searchParams.value = params;
        }
        loading.value = true;
        if (!isRefresh.value) tableData.value = []; // 清空原来数据

        try {
            const { data } = await getDataFn({ ...page.value, ...searchParams.value });
            searchParams.value = handleDelKey(searchParams.value, ['current', 'size']); // 每次拉取完数据剔除一下searchParams中的分页数据，避免和page重叠

            if (data) {
                //设备甘特图
                if (data.ganttDatas) {
                    const {
                        ganttDatas: { current, size, total: GTotal, records },
                        planEndDate,
                        planStartDate
                    } = data;
                    tableData.value = records.map((item: any, index: any) => {
                        return {
                            ...item,
                            rowIndex: index
                        };
                    });
                    startDate.value = planStartDate;
                    page.value = { current, size };
                    total.value = GTotal;
                    time.value = [new Date(planStartDate), new Date(planEndDate)];
                } else {
                    //订单甘特图
                    const {
                        rows: { current, size, total: GTotal, records },
                        localDateSortedSet
                    } = data;
                    const tables = addUniqueIds(records);
                    const spreadData = flattenArrays(tables);
                    tableData.value = spreadData.map((item: any, index: any) => {
                        if (item.node || item.children) {
                            return {
                                ...item,
                                rowIndex: index,
                                hide: true
                            };
                        } else {
                            return {
                                ...item,
                                rowIndex: index
                            };
                        }
                    });
                    const startTime = localDateSortedSet ? localDateSortedSet[0] : undefined;
                    startDate.value = startTime;
                    page.value = { current, size };
                    total.value = GTotal;
                    time.value = [
                        new Date(startTime),
                        new Date(
                            localDateSortedSet
                                ? localDateSortedSet[localDateSortedSet.length - 1]
                                : undefined
                        )
                    ];
                }
            }
        } catch (error) {
            console.log(error);
        }
        loading.value = false;
        isRefresh.value = false;
    };

    onBeforeMount(async () => {
        getData();
    });
    return {
        page,
        time,
        total,
        getData,
        loading,
        startDate,
        tableData,
        sizeChange,
        handleRefresh,
        currentChange
    };
};
