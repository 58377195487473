/*
 * @Description: 表格详情页吸顶效果
 * @Author: James324
 * @Date: 2024-01-17 14:09:08
 * @LastEditors: James324
 * @LastEditTime: 2024-01-17 14:25:48
 */

/**
 * @param {boolean} sticky 是否粘贴定位
 * @param {boolean} initOnce 只初始化一次
 */
export default (sticky?: boolean, initOnce?: boolean) => {
    const detailPageSticky = ref();
    const initSticky = () => {
        if (sticky == null) return;

        setTimeout(() => {
            detailPageSticky.value = sticky;
        }, 500);
    };

    if (initOnce) {
        onMounted(() => {
            initSticky();
        });
    } else {
        onActivated(() => {
            initSticky();
        });
    }

    onBeforeRouteLeave(() => {
        detailPageSticky.value = false;
    });

    return {
        detailPageSticky
    };
};
