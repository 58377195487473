/*
 * @Description: 路由列表
 * @Author: James324
 * @Date: 2023-08-18 13:34:57
 * @LastEditors: James324
 * @LastEditTime: 2023-11-28 13:16:25
 */
import { createRouter, createWebHashHistory } from 'vue-router';
import { constantRoute } from './routes';
import { scrollBehavior } from './helper';

const router = createRouter({
    history: createWebHashHistory(),
    routes: constantRoute,
    scrollBehavior
});

export default router;
