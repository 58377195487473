<script lang="ts">
import { type App } from 'vue';
import Modal from './modal/index.vue';
import confirm from './confirm.ts';
import destroyFns from './destroyFns.ts';
import useType, { type ModalFuncProps } from './hooks/useType';

const { withInfo, withSuccess, withError, withWarn, withConfirm } = useType();

function modalWarn(props: ModalFuncProps) {
    return confirm(withWarn(props));
}

Modal.info = function infoFn(props: ModalFuncProps) {
    return confirm(withInfo(props));
};

Modal.success = function successFn(props: ModalFuncProps) {
    return confirm(withSuccess(props));
};

Modal.error = function errorFn(props: ModalFuncProps) {
    return confirm(withError(props));
};

Modal.warning = modalWarn;

Modal.warn = modalWarn;

Modal.confirm = function confirmFn(props: ModalFuncProps) {
    return confirm(withConfirm(props));
};

Modal.destroyAll = function destroyAllFn() {
    while (destroyFns.length) {
        const close = destroyFns.pop();
        if (close) {
            close();
        }
    }
};

/* 全局安装 */
Modal.install = function (app: App) {
    app.component(Modal.name, Modal);
    return app;
};

type Custom = Record<
    'info' | 'success' | 'error' | 'warning' | 'warn' | 'confirm',
    (props: ModalFuncProps) => { close: () => void }
>;

type TModal = typeof Modal & Custom;

export default Modal as TModal;
</script>
