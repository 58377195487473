/*
 * @Description: i18n 国际化语言
 * @Author: James324
 * @Date: 2023-08-30 09:02:48
 * @LastEditors: James324
 * @LastEditTime: 2023-09-01 13:14:18
 */

import { createI18n } from 'vue-i18n';
import zh from './lang/zh';
import en from './lang/en';
import { LocalStorage } from '@/utils/storage';
import { LOCAL_STATE } from '@/config';

const messages = {
    'en-US': en,
    'zh-US': zh
};
const localeStorage = LocalStorage.get(LOCAL_STATE) && LocalStorage.get(LOCAL_STATE).local;
const language = (navigator.language || 'zh-US').toLocaleLowerCase(); // 这是获取浏览器的语言

const i18n = createI18n({
    legacy: false, // 使用 Composition API 模式，则需要将其设置为false
    globalInjection: true, // 全局注入 $t 函数
    locale: localeStorage || language.replace('-cn', '-US') || 'zh-US', // 首先从缓存里拿，没有的话就用浏览器语言，
    fallbackLocale: 'zh-US', // 设置备用语言
    messages
});

export default i18n;
