/**
 * 插槽渲染
 */
export const renderSomeContent = (someContent: any) => {
    if (typeof someContent === 'function') {
        return someContent();
    }
    return someContent;
};

/**
 * 判断是否为 document 文档对象
 */
export const canUseDom = () => {
    return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
};

export const canUseDocElement = () => canUseDom() && window.document.documentElement;

/**
 * 获取元素滚轮距离
 */
export const getScroll = (w: Window, top?: boolean): number => {
    let ret = w[`page${top ? 'Y' : 'X'}Offset`];
    const method = `scroll${top ? 'Top' : 'Left'}`;
    if (typeof ret !== 'number') {
        const d: Record<string, any> = w.document;
        ret = d.documentElement[method];
        if (typeof ret !== 'number') {
            ret = d.body[method];
        }
    }
    return ret;
};

/**
 * 元素相对视口的距离
 */
type CompatibleDocument = {
    parentWindow?: Window;
} & Document;
export const offset = (el: Element) => {
    const rect = el.getBoundingClientRect();
    const pos = {
        left: rect.left,
        top: rect.top
    };
    const doc = el.ownerDocument as CompatibleDocument;
    const w = (doc.defaultView || doc.parentWindow) as Window;
    pos.left += getScroll(w);
    pos.top += getScroll(w, true);
    return pos;
};

/**
 * 单位 unit 处理
 */
export const toPx = (unit: string | number) => {
    if (typeof unit === 'string' && unit !== 'auto') {
        return unit.includes('px') ? unit : `${unit}px`;
    } else if (unit === 'auto') {
        return unit;
    } else {
        return `${unit}px`;
    }
};

/**
 * 获取 DOM 元素
 */
export const getDom = <T extends HTMLElement>(
    selector: string,
    parent: HTMLElement | Document = document
): T | null => parent.querySelector(selector);
