/*
 * @Description: 路由守卫
 * @Author: James324
 * @Date: 2023-08-18 15:48:49
 * @LastEditors: James324
 * @LastEditTime: 2024-03-15 13:32:37
 */
import router from '../index';
import { constantRoute, NotFoundRoute, userAsyncRoutes } from '../routes';
import { handleAsyncRoute, setTransitionName, initSetDark } from '../helper';
import { getToken } from '@/utils';
import { Message } from '@/instance';
import { Global, WhiteList } from '@/config';
import { GlobalStore } from '@/store';
import type { Route, RouteRecordRaw } from 'vue-router';
import nProgress from 'nprogress';
import 'nprogress/nprogress.css';

nProgress.configure({
    showSpinner: false
});

const setDocumentTitle = (title: string = Global.title) => {
    document.title = title;
};

// 检查是否存在于免登陆白名单
const inWhiteList = (toPath: string) => {
    const path = WhiteList.find(value => {
        const reg = new RegExp('^' + value);
        return reg.test(toPath);
    });
    return !!path;
};

// 清除动态路由
const clearAsyncRoute = () => {
    [...UserAsyncRoute, ...NotFoundRoute].forEach((route: Route) => {
        if (router.hasRoute(route.name!)) router.removeRoute(route.name!);
    });
};

let isGetAsyncRoutes = false; //这个是用于判断动态路由是否已经被获取
const UserAsyncRoute: Route[] = [];

router.beforeEach(async (to, from, next) => {
    nProgress.start();

    const title = to.meta.title as string;
    const token = getToken();

    if (title) {
        setDocumentTitle(title);
    }

    // 检查to.path是否存在于免登陆白名单
    if (inWhiteList(to.path)) {
        if (to.path === '/login') {
            if (token) {
                next('/home');
            } else {
                isGetAsyncRoutes = false;
                clearAsyncRoute(); // 清除路由
                next();
            }
            return;
        }
    }

    if (!token) {
        isGetAsyncRoutes = false;
        clearAsyncRoute();
        next({ path: '/login', query: { redirect: decodeURIComponent(from.path) } });
    } else {
        if (!isGetAsyncRoutes) {
            const globalStore = GlobalStore();
            const TagsViewStore = tagsViewStore();
            const { asyncMenus, buttons } = storeToRefs(globalStore);

            const res: any = await globalStore.getUserInfo(); // 获取用户信息

            if (res) {
                const menus = res.menus || asyncMenus.value;
                const UserAsyncRoute = globalStore.isSuperAdmin // 开发环境或者超管拥有所有路由权限
                    ? userAsyncRoutes
                    : handleAsyncRoute(menus, res.buttons || buttons);

                const menuAsyncRoutes = [...UserAsyncRoute, ...NotFoundRoute];

                globalStore.setAsyncMenus(menus);
                globalStore.setAllRoutes(constantRoute, menuAsyncRoutes);
                globalStore.setAsyncRoutes(UserAsyncRoute);

                TagsViewStore.removeNoExistsTag(asyncMenus.value); // 移除不存在的标签

                menuAsyncRoutes.forEach((route: Route) => {
                    if (router.hasRoute(route.name!)) return; // 存在路由记录
                    if (route.level === 1 || !route.parent) {
                        router.addRoute(route as RouteRecordRaw);
                    } else {
                        router.addRoute(route.parent, route as RouteRecordRaw);
                    }
                });

                if (!UserAsyncRoute.length) {
                    // 没有任何菜单权限
                    Message.error('无权限访问菜单，请联系管理员！');
                    next('/login');
                    return;
                }

                isGetAsyncRoutes = true;
            }
            next({ ...to, replace: true }); // 解决路由动态添加后刷新空白
        } else {
            next();
        }
    }
});

// 全局后置钩子
router.afterEach((to, from) => {
    nProgress.done(true);
    setTransitionName(to, from); // 设置路由动画
    initSetDark(to.path); // 初始化暗黑模式
});

// 全局捕获页面异步错误
window.addEventListener('unhandledrejection', () => {
    nProgress.done(true);
});
